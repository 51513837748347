import React from 'react';
import logo from '../assets/img/logo-centered.png';
import Footer from './Footer';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';

let firstName = "";
let lastName = "";
export default class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            c_password: ""
        };
    }

    componentDidMount(){
        document.title = "Sign Up - Pie Slicer";
    }

    signUp = (e) => {
        e.preventDefault();
        var password = this.state.password;
        var c_password = this.state.c_password;
            if(password !== c_password) {
                swal({
                title: "Password didn't match! Try again.",
                type: "error"
                }).then(result => {
                    // window.location.reload();
                });
            }
            else{   
                let apiHandler = new ApiHandler();

                apiHandler.push(
                "register",
                "POST",
                {
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password,
                    c_password: this.state.c_password,
                
                },
                function(response) {
                console.log(response);
                if(response.status === 200) {
                    localStorage.removeItem('pie_name');
                        swal({
                        title: "Account created Successfully!",
                        type: "success"
                        }).then(result => {
                            window.location.href = '/';
                        });
                } 
                else {
                    swal({
                    title: "Error! Please check information",
                    type: "error"
                    }).then(result => {
                    });
                }
                }
                );
            }
    }
    
    handleChangeFirstName = (evt) => {
        firstName = evt.target.value;
    }

    handleChangeLastName = (evt) => {
        lastName = evt.target.value;
        this.setState({name: firstName + " " + lastName});
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

     render () {
        return(
        <div>
            <div className="login-container" id="signup">
                <div className="page-container">
                    <div className="page-content">
                            <div className="content-wrapper">
                                <center>
                                    <img src={logo} id="login-logo" alt="img-logo"/><br />
                                    <span className="text-trial-signup">All plans start with 14 days FREE!</span>
                                </center>
                                <br/>
                                <form onSubmit={this.signUp}>
                                    <div className="panel panel-body registration-form">
                                        <div className="text-center">
                                            <h5 className="content-group">Create an Account</h5>
                                        </div>
                                        <div className="form-group has-feedback">
                                            <select className="form-control" required>
                                                <option>Subscription: Monthly ($19.95), 14-days FREE</option>
                                                <option>Subscription: Annual ($199.95), 14-days FREE</option>
                                                <option>Subscription: Tri-Annual ($299.95), 14-days FREE</option>
                                            </select>
                                        </div>
                                        <div className="form-group has-feedback">
                                            <input type="text" className="form-control" placeholder="Pie Name" required autoFocus/>
                                        </div>
                                        <div className="form-group has-feedback">
                                            <input type="text" className="form-control pull-left" placeholder="Your Fair Market Salary Less Cash Compensation" required style={{ width: '95%' }} />
                                            <span><i className="fa fa-question-circle" style={{paddingTop: "10px", paddingLeft: "10px"}}></i></span>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="content-divider text-muted form-group"><span>Account Information</span></div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="firstname" placeholder="First Name *" required onChange={this.handleChangeFirstName} />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="lastname" placeholder="Last Name *" required onChange={this.handleChangeLastName} />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="email" className="form-control" name="email" placeholder="Email Address *" required value={this.state.email} onChange={this.handleChange} />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="password" className="form-control" name="password" placeholder="Password *" required value={this.state.password} onChange={this.handleChange}/>
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="password" className="form-control" name="c_password" placeholder="Re-type Password *" required value={this.state.c_password} onChange={this.handleChange}/>
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="address" placeholder="Address" />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="city" placeholder="City" />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <select className="form-control" name="country" id="country">
                                                        <option required="required">Select country</option>
                                                        <option value={"AF"}>Afghanistan</option>
                                                        <option value={"AX"}>Aland Islands</option>
                                                        <option value={"AL"}>Albania</option>
                                                        <option value={"DZ"}>Algeria</option>
                                                        <option value={"AS"}>American Samoa</option>
                                                        <option value={"AD"}>Andorra</option>
                                                        <option value={"AO"}>Angola</option>
                                                        <option value={"AI"}>Anguilla</option>
                                                        <option value={"AQ"}>Antarctica</option>
                                                        <option value={"AG"}>Antigua and Barbuda</option>
                                                        <option value={"AR"}>Argentina</option>
                                                        <option value={"AM"}>Armenia</option>
                                                        <option value={"AW"}>Aruba</option>
                                                        <option value={"AU"}>Australia</option>
                                                        <option value={"AT"}>Austria</option>
                                                        <option value={"AZ"}>Azerbaijan</option>
                                                        <option value={"BS"}>Bahamas</option>
                                                        <option value={"BH"}>Bahrain</option>
                                                        <option value={"BD"}>Bangladesh</option>
                                                        <option value={"BB"}>Barbados</option>
                                                        <option value={"BY"}>Belarus</option>
                                                        <option value={"BE"}>Belgium</option>
                                                        <option value={"BZ"}>Belize</option>
                                                        <option value={"BJ"}>Benin</option>
                                                        <option value={"BM"}>Bermuda</option>
                                                        <option value={"BT"}>Bhutan</option>
                                                        <option value={"BO"}>Bolivia</option>
                                                        <option value={"BQ"}>Bonaire, Saint Eustatius and Saba</option>
                                                        <option value={"BA"}>Bosnia and Herzegovina</option>
                                                        <option value={"BW"}>Botswana</option>
                                                        <option value={"BV"}>Bouvet Island</option>
                                                        <option value={"BR"}>Brazil</option>
                                                        <option value={"IO"}>British Indian Ocean Territory</option>
                                                        <option value={"VG"}>British Virgin Islands</option>
                                                        <option value={"BN"}>Brunei</option>
                                                        <option value={"BG"}>Bulgaria</option>
                                                        <option value={"BF"}>Burkina Faso</option>
                                                        <option value={"BI"}>Burundi</option>
                                                        <option value={"KH"}>Cambodia</option>
                                                        <option value={"CM"}>Cameroon</option>
                                                        <option value={"CA"}>Canada</option>
                                                        <option value={"CV"}>Cape Verde</option>
                                                        <option value={"KY"}>Cayman Islands</option>
                                                        <option value={"CF"}>Central African Republic</option>
                                                        <option value={"TD"}>Chad</option>
                                                        <option value={"CL"}>Chile</option>
                                                        <option value={"CN"}>China</option>
                                                        <option value={"CX"}>Christmas Island</option>
                                                        <option value={"CC"}>Cocos Islands</option>
                                                        <option value={"CO"}>Colombia</option>
                                                        <option value={"KM"}>Comoros</option>
                                                        <option value={"CK"}>Cook Islands</option>
                                                        <option value={"CR"}>Costa Rica</option>
                                                        <option value={"HR"}>Croatia</option>
                                                        <option value={"CU"}>Cuba</option>
                                                        <option value={"CW"}>Curacao</option>
                                                        <option value={"CY"}>Cyprus</option>
                                                        <option value={"CZ"}>Czech Republic</option>
                                                        <option value={"CD"}>Democratic Republic of the Congo</option>
                                                        <option value={"DK"}>Denmark</option>
                                                        <option value={"DJ"}>Djibouti</option>
                                                        <option value={"DM"}>Dominica</option>
                                                        <option value={"DO"}>Dominican Republic</option>
                                                        <option value={"TL"}>East Timor</option>
                                                        <option value={"EC"}>Ecuador</option>
                                                        <option value={"EG"}>Egypt</option>
                                                        <option value={"SV"}>El Salvador</option>
                                                        <option value={"GQ"}>Equatorial Guinea</option>
                                                        <option value={"ER"}>Eritrea</option>
                                                        <option value={"EE"}>Estonia</option>
                                                        <option value={"ET"}>Ethiopia</option>
                                                        <option value={"FK"}>Falkland Islands</option>
                                                        <option value={"FO"}>Faroe Islands</option>
                                                        <option value={"FJ"}>Fiji</option>
                                                        <option value={"FI"}>Finland</option>
                                                        <option value={"FR"}>France</option>
                                                        <option value={"GF"}>French Guiana</option>
                                                        <option value={"PF"}>French Polynesia</option>
                                                        <option value={"TF"}>French Southern Territories</option>
                                                        <option value={"GA"}>Gabon</option>
                                                        <option value={"GM"}>Gambia</option>
                                                        <option value={"GE"}>Georgia</option>
                                                        <option value={"DE"}>Germany</option>
                                                        <option value={"GH"}>Ghana</option>
                                                        <option value={"GI"}>Gibraltar</option>
                                                        <option value={"GR"}>Greece</option>
                                                        <option value={"GL"}>Greenland</option>
                                                        <option value={"GD"}>Grenada</option>
                                                        <option value={"GP"}>Guadeloupe</option>
                                                        <option value={"GU"}>Guam</option>
                                                        <option value={"GT"}>Guatemala</option>
                                                        <option value={"GG"}>Guernsey</option>
                                                        <option value={"GN"}>Guinea</option>
                                                        <option value={"GW"}>Guinea-Bissau</option>
                                                        <option value={"GY"}>Guyana</option>
                                                        <option value={"HT"}>Haiti</option>
                                                        <option value={"HM"}>Heard Island and McDonald Islands</option>
                                                        <option value={"HN"}>Honduras</option>
                                                        <option value={"HK"}>Hong Kong</option>
                                                        <option value={"HU"}>Hungary</option>
                                                        <option value={"IS"}>Iceland</option>
                                                        <option value={"IN"}>India</option>
                                                        <option value={"ID"}>Indonesia</option>
                                                        <option value={"IR"}>Iran</option>
                                                        <option value={"IQ"}>Iraq</option>
                                                        <option value={"IE"}>Ireland</option>
                                                        <option value={"IM"}>Isle of Man</option>
                                                        <option value={"IL"}>Israel</option>
                                                        <option value={"IT"}>Italy</option>
                                                        <option value={"CI"}>Ivory Coast</option>
                                                        <option value={"JM"}>Jamaica</option>
                                                        <option value={"JP"}>Japan</option>
                                                        <option value={"JE"}>Jersey</option>
                                                        <option value={"JO"}>Jordan</option>
                                                        <option value={"KZ"}>Kazakhstan</option>
                                                        <option value={"KE"}>Kenya</option>
                                                        <option value={"KI"}>Kiribati</option>
                                                        <option value={"XK"}>Kosovo</option>
                                                        <option value={"KW"}>Kuwait</option>
                                                        <option value={"KG"}>Kyrgyzstan</option>
                                                        <option value={"LA"}>Laos</option>
                                                        <option value={"LV"}>Latvia</option>
                                                        <option value={"LB"}>Lebanon</option>
                                                        <option value={"LS"}>Lesotho</option>
                                                        <option value={"LR"}>Liberia</option>
                                                        <option value={"LY"}>Libya</option>
                                                        <option value={"LI"}>Liechtenstein</option>
                                                        <option value={"LT"}>Lithuania</option>
                                                        <option value={"LU"}>Luxembourg</option>
                                                        <option value={"MO"}>Macao</option>
                                                        <option value={"MK"}>Macedonia</option>
                                                        <option value={"MG"}>Madagascar</option>
                                                        <option value={"MW"}>Malawi</option>
                                                        <option value={"MY"}>Malaysia</option>
                                                        <option value={"MV"}>Maldives</option>
                                                        <option value={"ML"}>Mali</option>
                                                        <option value={"MT"}>Malta</option>
                                                        <option value={"MH"}>Marshall Islands</option>
                                                        <option value={"MQ"}>Martinique</option>
                                                        <option value={"MR"}>Mauritania</option>
                                                        <option value={"MU"}>Mauritius</option>
                                                        <option value={"YT"}>Mayotte</option>
                                                        <option value={"MX"}>Mexico</option>
                                                        <option value={"FM"}>Micronesia</option>
                                                        <option value={"MD"}>Moldova</option>
                                                        <option value={"MC"}>Monaco</option>
                                                        <option value={"MN"}>Mongolia</option>
                                                        <option value={"ME"}>Montenegro</option>
                                                        <option value={"MS"}>Montserrat</option>
                                                        <option value={"MA"}>Morocco</option>
                                                        <option value={"MZ"}>Mozambique</option>
                                                        <option value={"MM"}>Myanmar</option>
                                                        <option value={"NA"}>Namibia</option>
                                                        <option value={"NR"}>Nauru</option>
                                                        <option value={"NP"}>Nepal</option>
                                                        <option value={"NL"}>Netherlands</option>
                                                        <option value={"NC"}>New Caledonia</option>
                                                        <option value={"NZ"}>New Zealand</option>
                                                        <option value={"NI"}>Nicaragua</option>
                                                        <option value={"NE"}>Niger</option>
                                                        <option value={"NG"}>Nigeria</option>
                                                        <option value={"NU"}>Niue</option>
                                                        <option value={"NF"}>Norfolk Island</option>
                                                        <option value={"KP"}>North Korea</option>
                                                        <option value={"MP"}>Northern Mariana Islands</option>
                                                        <option value={"NO"}>Norway</option>
                                                        <option value={"OM"}>Oman</option>
                                                        <option value={"PK"}>Pakistan</option>
                                                        <option value={"PW"}>Palau</option>
                                                        <option value={"PS"}>Palestinian Territory</option>
                                                        <option value={"PA"}>Panama</option>
                                                        <option value={"PG"}>Papua New Guinea</option>
                                                        <option value={"PY"}>Paraguay</option>
                                                        <option value={"PE"}>Peru</option>
                                                        <option value={"PH"}>Philippines</option>
                                                        <option value={"PN"}>Pitcairn</option>
                                                        <option value={"PL"}>Poland</option>
                                                        <option value={"PT"}>Portugal</option>
                                                        <option value={"PR"}>Puerto Rico</option>
                                                        <option value={"QA"}>Qatar</option>
                                                        <option value={"CG"}>Republic of the Congo</option>
                                                        <option value={"RE"}>Reunion</option>
                                                        <option value={"RO"}>Romania</option>
                                                        <option value={"RU"}>Russia</option>
                                                        <option value={"RW"}>Rwanda</option>
                                                        <option value={"BL"}>Saint Barthelemy</option>
                                                        <option value={"SH"}>Saint Helena</option>
                                                        <option value={"KN"}>Saint Kitts and Nevis</option>
                                                        <option value={"LC"}>Saint Lucia</option>
                                                        <option value={"MF"}>Saint Martin</option>
                                                        <option value={"PM"}>Saint Pierre and Miquelon</option>
                                                        <option value={"VC"}>Saint Vincent and the Grenadines</option>
                                                        <option value={"WS"}>Samoa</option>
                                                        <option value={"SM"}>San Marino</option>
                                                        <option value={"ST"}>Sao Tome and Principe</option>
                                                        <option value={"SA"}>Saudi Arabia</option>
                                                        <option value={"SN"}>Senegal</option>
                                                        <option value={"RS"}>Serbia</option>
                                                        <option value={"SC"}>Seychelles</option>
                                                        <option value={"SL"}>Sierra Leone</option>
                                                        <option value={"SG"}>Singapore</option>
                                                        <option value={"SX"}>Sint Maarten</option>
                                                        <option value={"SK"}>Slovakia</option>
                                                        <option value={"SI"}>Slovenia</option>
                                                        <option value={"SB"}>Solomon Islands</option>
                                                        <option value={"SO"}>Somalia</option>
                                                        <option value={"ZA"}>South Africa</option>
                                                        <option value={"GS"}>South Georgia and the South Sandwich Islands</option>
                                                        <option value={"KR"}>South Korea</option>
                                                        <option value={"SS"}>South Sudan</option>
                                                        <option value={"ES"}>Spain</option>
                                                        <option value={"LK"}>Sri Lanka</option>
                                                        <option value={"SD"}>Sudan</option>
                                                        <option value={"SR"}>Suriname</option>
                                                        <option value={"SJ"}>Svalbard and Jan Mayen</option>
                                                        <option value={"SZ"}>Swaziland</option>
                                                        <option value={"SE"}>Sweden</option>
                                                        <option value={"CH"}>Switzerland</option>
                                                        <option value={"SY"}>Syria</option>
                                                        <option value={"TW"}>Taiwan</option>
                                                        <option value={"TJ"}>Tajikistan</option>
                                                        <option value={"TZ"}>Tanzania</option>
                                                        <option value={"TH"}>Thailand</option>
                                                        <option value={"TG"}>Togo</option>
                                                        <option value={"TK"}>Tokelau</option>
                                                        <option value={"TO"}>Tonga</option>
                                                        <option value={"TT"}>Trinidad and Tobago</option>
                                                        <option value={"TN"}>Tunisia</option>
                                                        <option value={"TR"}>Turkey</option>
                                                        <option value={"TM"}>Turkmenistan</option>
                                                        <option value={"TC"}>Turks and Caicos Islands</option>
                                                        <option value={"TV"}>Tuvalu</option>
                                                        <option value={"VI"}>U.S. Virgin Islands</option>
                                                        <option value={"UG"}>Uganda</option>
                                                        <option value={"UA"}>Ukraine</option>
                                                        <option value={"AE"}>United Arab Emirates</option>
                                                        <option value={"GB"}>United Kingdom</option>
                                                        <option value={"US"}>United States</option>
                                                        <option value={"UM"}>United States Minor Outlying Islands</option>
                                                        <option value={"UY"}>Uruguay</option>
                                                        <option value={"UZ"}>Uzbekistan</option>
                                                        <option value={"VU"}>Vanuatu</option>
                                                        <option value={"VA"}>Vatican</option>
                                                        <option value={"VE"}>Venezuela</option>
                                                        <option value={"VN"}>Vietnam</option>
                                                        <option value={"WF"}>Wallis and Futuna</option>
                                                        <option value={"EH"}>Western Sahara</option>
                                                        <option value={"YE"}>Yemen</option>
                                                        <option value={"ZM"}>Zambia</option>
                                                        <option value={"ZW"}>Zimbabwe</option>
                                                    </select>
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="state" placeholder="State" />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="zip_code" placeholder="Zip Code" />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" name="phone" placeholder="Phone" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="content-divider text-muted form-group"><span>Payment Options</span></div>

                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-danger btn-block">Submit</button>
                                                    <small className="display-block" style={{marginTop: "10px"}}>By clicking "Submit" you agree to the
                                                    <a target="_blank"> Terms of Service</a> and <a target="_blank"> Privacy Policy</a></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }

}