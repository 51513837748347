import React from 'react';
import HomeFooter from './HomeFooter';
import logo from '../assets/img/logo.png';

export default class Well extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // this.state = {
    //     //   open: false,
    //     // };
    // }

    componentDidMount() {

    }


    render() {
        return (
            <div className="layout-boxed navbar-top">
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{ marginRight: "7px" }}><img src={logo} style={{ width: "58px", height: "58px" }} className="img-rounded" alt="" /></span>
                                <span className="text-semibold header-pie-name">MosquitOasis <i className="icon-arrow-down5 change-pie"></i></span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="panel panel-flat">
                                        <div className="panel-heading">
                                            <h6 className="panel-title" style={{marginTop: "8px !important", marginBottom: "8px !important"}}>Well Balance</h6>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td nowrap>
                                                            <span className="grunt-name" style={{color: "#bf0000" , borderColor: "#bf0000"}}>Jayson</span>
                                                        </td>
                                                        <td align="right">0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td nowrap>"
                                                            <span className="grunt-name" style={{color: "#e46c0a", borderColor: "#e46c0a"}}>Mike</span>
                                                        </td>
                                                        <td align="right">0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td nowrap>
                                                            <span className="grunt-name" style={{color: "#17375e", borderColor: "#17375e"}}>Kier</span>
                                                        </td>
                                                        <td align="right">0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td nowrap>
                                                            <span className="grunt-name" style={{color: "#77933c", borderColor: "#77933c"}}>Ricardo</span>
                                                        </td>
                                                        <td align="right">0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td nowrap>
                                                            <span className="grunt-name" style={{color: "#604a7b", borderColor: "#604a7b"}}>Mat</span>
                                                        </td>
                                                        <td align="right">0.00</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td><span className="text-bold">Total</span></td>
                                                        <td align="right"><span className="text-bold">0.00</span></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="panel panel-flat">
                                        <div className="table-responsive">
                                            <table className="table datatable-lumpsum table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th>Added</th>
                                                        <th>Withdrawn</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>10/11/2017</td>
                                                        <td><span className="grunt-name" style={{color: "#bf0000", borderColor: "#bf0000"}}>Jayson</span></td>
                                                        <td></td>
                                                        <td align="right">1,000.00</td>
                                                        <td align="right"></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10/11/2017</td>
                                                        <td><span className="grunt-name" style={{color: "#e46c0a", borderColor: "#e46c0a"}}>Mike</span></td>
                                                        <td></td>
                                                        <td align="right">1,200.00</td>
                                                        <td align="right"></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10/11/2017</td>
                                                        <td><span className="grunt-name" style={{color: "#17375e", borderColor: "#17375e"}}>Kier</span></td>
                                                        <td></td>
                                                        <td align="right">500.00</td>
                                                        <td align="right">1,000.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10/11/2017</td>
                                                        <td><span className="grunt-name" style={{color: "#77933c", borderColor: "#77933c"}}>Ricardo</span></td>
                                                        <td></td>
                                                        <td align="right">1,500.00</td>
                                                        <td align="right">2,000.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10/11/2017</td>
                                                        <td><span className="grunt-name" style={{color: "#604a7b", borderColor: "#604a7b"}}>Mat</span></td>
                                                        <td></td>
                                                        <td align="right">200.00</td>
                                                        <td align="right"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </div>
        );
    }

}