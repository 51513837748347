import React from 'react';
// import './App.css';
import HomeCreatePie from './components/HomeCreatePie';
import WelcomeRoute from './components/WelcomeRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap.css';
import './assets/css/colors.css';
import './assets/css/components.css';
import './assets/css/core.css';
import './assets/css/custom.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/global_assets/css/icons/icomoon/styles.css';
import './Loader.js';
import '../src/assets/global_assets/css/icons/icomoon/styles.css';



class App extends React.Component {
  render() {
    var page = (localStorage.getItem("token")) ? (<HomeCreatePie />) : (<WelcomeRoute />);
    return page;
  }
}

export default App;
