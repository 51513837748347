import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../assets/css/customize.css';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';

export default class AddTeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          name: "",
          jobtitle: "",
          email: "",
          salary: "",
          account_type: "",
          restriction: "",
          open: false,
          pie_id: localStorage.getItem('def_id'),
          
        }
      };


    componentDidMount() {

      if(localStorage.getItem('aff')) {
        this.setState( {pie_id: localStorage.getItem('aff')} );
      }

    }
    

    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {

      this.setState({
          [event.target.id]: event.target.value
      });
      
    };

    handleAddMember = (event) => {
      var token = localStorage.getItem('token');
      event.preventDefault();
      let apiHandler = new ApiHandler();

      var headers = {
        Authorization: "Bearer " + token
      }

      apiHandler.push(
      "grunt",
      "POST",
      {
          pie_id: this.state.pie_id,
          jobtitle: this.state.jobtitle,
          email: this.state.email,
          salary: this.state.salary,
          account_type: this.state.account_type,
          restriction: this.state.restriction,
      
      },
      function(response) {
      console.log(response);
      if(response.status === 200) {
          // valid
              document.getElementById('btn_hide').click();
              swal({
              title: "Member Added Successfully!",
              type: "success"
              }).then(result => {
                window.location.href="/";
              });
      }  
      else {
          swal({
          title: "Invalid Login",
          type: "error"
          }).then(result => {
          });
      }
      }, headers
      );
    }

    render() {
        return(
            <div>
            <Button id="add-team-member" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
              Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title"><i className="icon-user-plus"></i> &nbsp;Add Team Member</DialogTitle>
              <DialogContent>
            <form onSubmit={this.handleAddMember}>
                <div className="form-group">
                  <label>Team Member Name <span className="requiredFields">*</span></label>
                  <input type="text" className="form-control" name="name" id="name" required onChange={this.handleChange} value={this.state.name}/>
				        </div>

                <div className="form-group">
                  <label>Job Title<span className="requiredFields">*</span></label>
                  <input type="text" className="form-control" name="jobtitle" id="jobtitle" required onChange={this.handleChange} value={this.state.jobtitle}/>
			        	</div>

                <div className="form-group">
                  <label>Email Address <span className="requiredFields">*</span></label>
                  <input type="email" className="form-control" name="email" id="email" required onChange={this.handleChange} value={this.state.email}/>
                </div>

                <div className="form-group">
                  <label>Fair Market Salary Less Cash Compensation <span className="requiredFields">*</span></label>
                  <input type="text" className="form-control" name="salary" id="salary" required onChange={this.handleChange} value={this.state.salary}/>
                </div>

                <div className="form-group">
                  <label>Account Type <span className="requiredFields">*</span></label>
                  <select className="form-control" name="account_type" id="account_type" required value={this.state.account_type} onChange={this.handleChange} >
                    {/* <option value selected="selected" disabled="disabled">Select account type</option> */}
                    <option value={0}>None</option>
                    <option value={4}>Employee</option>
                    <option value={2}>Executive</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Restriction <span className="requiredFields">*</span></label>
                  <select className="form-control" name="restriction" id="restriction" required value={this.state.restriction} onChange={this.handleChange} >
                    <option value={0}>None</option>
                    <option value={1}>Contributions Require Approval</option>
                    <option value={2}>View Only</option>
                  </select>
                </div>
                
                {/* <input type="submit" className="btn btn-danger pull-left" onClick={this.AddTeamMember}  /> */}
                <button type="submit" className="btn btn-danger pull-left"><i className="icon-check"></i> Add Team Member</button>
                <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                </form>
              </DialogContent>
              <DialogActions>
                 {/* <button type="submit" className="btn btn-danger pull-left"><i className="icon-check"></i> Add Team Member</button>  */}
                <button id="btn_hide" className="btn btn-danger pull-right" onClick={this.handleClose} style={{display: "none"}}><i className="icon-cross"></i> Cancel</button>
               </DialogActions>
            </Dialog>
          </div>
        );
    }
}