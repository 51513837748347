import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import ReactChip from 'react-chip';
import {
    Slider,
    SliderInput,
    SliderTrack,
    SliderTrackHighlight,
    SliderHandle,
    SliderMarker,
} from "@reach/slider";
import "@reach/slider/styles.css";
import Switch from 'react-switchery';
import user_2 from '../assets/img/user_2.jpg';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';
import '../assets/css/customize.css';
import ResetPie from './ResetPie';
import DeletePie from './DeletePie';

class PieSettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pname: "",
            currency: "",
            noncashx: "",
            cashx: "",
            comrate: "",
            rorate: "",
            Apct: "",
            Bpct: "",
            A: "",
            car: 0,
            unit: "",
            reimbrate: "",
            overtime_enable: 0,
            overtime_hours: "",
            overtime_multiplier: "",
            email_alerts: "",
            tags: "",
            checked: false,
            labelMission: "OFF",
            labelSummary: "OFF",
            labelLocation: "OFF"

        }
    }

    handleClickOpen = () => {
        this.setState( {open: true} );
    }

    handleClose = () => {
        this.setState( {open: false} );
    }

    handleCommissionRate = (val) => {
        this.setState( {comrate: val} );
    }

    handleRoyaltyRate = (val) => {
        this.setState( {rorate: val} );
    }

    handleApct = (val) => {
        this.setState( {Apct: val} );
    }

    handleBpct = (val) => {
        this.setState( {Bpct: val} );
    }


    selectPersonalCarSettings = (event) => {
        let val = event.target.value;
        var div = document.getElementById('metric-unit-row');
            if(val != 0) {
                div.style.display = "block";
                this.setState( {car: val} );
            }
            else {
                div.style.display = "none";
            }
    }

    checkOvertime = (evt) => {

        var checkbox = document.getElementById('enable_overtime');
        var overtimeDiv = document.getElementById('overtime-fields-row');
        if(checkbox.checked) {
            overtimeDiv.style.display = "block";
            this.setState( {overtime_enable: evt.target.value} );
        }
        else{
            overtimeDiv.style.display = "none";
            this.setState( {overtime_enable: 0} );
        }
    }

    handleProjects = (projects) => {
        this.setState( {tags: projects} );
    }

    handleInput = (event) => {
        this.setState( {[event.target.name]: event.target.value} );
    }

    handleMetricUnit = () => {
        var radio = document.querySelector('input[name="unit"]:checked').value;
        this.setState( {unit: radio} );
    }

    handleEmailAlerts = (evt) => {
        var radio = document.querySelector('input[name="email_alerts"]:checked').value;
            this.setState( {email_alerts: radio} );
    }

    handleUploadImg = (event) => {
        var reader = new FileReader();
        var file = event.target.files[0];
        var fileName = document.getElementById('filename').value = file.name;
        var self = this;

            reader.onload = function(upload) {
                var imgDiv = document.getElementById('logo_img');
                    imgDiv.src = upload.target.result;
            //   self.setState( {image: upload.target.result} );
            };
            reader.readAsDataURL(file);
    }

    handlePieSettings = (event) => {
        var token = localStorage.getItem('token');
        let apiHandler = new ApiHandler();
        event.preventDefault();


        var headers = {
            Authorization: "Bearer " + token
        }
        
            apiHandler.push(
            "pie",
            "PUT",
            {
                
                pie_id: localStorage.getItem('aff'),
                user_id: localStorage.getItem('userID'),
                action: "update",
                pname:  this.state.name,
                noncashx: this.state.noncashx,
                cashx: this.state.cashx,
                comrate: this.state.comrate,
                rorate: this.state.rorate,
                Apct: this.state.Apct,
                A: this.state.A,
                car: this.state.car,
                unit: this.state.unit,
                reimbrate: this.state.reimbrate,
                Bpct: this.state.Bpct,
                overtime_enable: this.state.overtime_enable,
                overtime_hours: this.state.overtime_hours,
                overtime_multiplier: this.state.overtime_multiplier,
                currency: this.state.currency
        
            },
            function(response) {
            console.log(response);
            if(response.status === 200) {
                // valid
                    document.getElementById('cancel_btn').click();
                    swal({
                    title: "Pie Settings Saved Successfully!",
                    type: "success"
                    }).then(result => {
                    window.location.href="/";
                    });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );
    }

    handleSwitchMission = (value) => {
        
        if(value === true) {
            this.setState( {labelMission: "ON"} );
        }
        else{
            this.setState( {labelMission: "OFF"} );
        }
    }

    handleSwitchSummary = (value) => {
        
        if(value === true) {
            this.setState( {labelSummary: "ON"} );
        }
        else{
            this.setState( {labelSummary: "OFF"} );
        }
    }

    handleSwitchLocation = (value) => {
        
        if(value === true) {
            this.setState( {labelLocation: "ON"} );
        }
        else{
            this.setState( {labelLocation: "OFF"} );
        }
    }

    resetPie = () => {
        document.getElementById('reset-pie').click();
        document.getElementById('cancel_btn').click();
    }

    deletePie = () => {
        document.getElementById('delete-pie').click();
        document.getElementById('cancel_btn').click();
    }
    
    render() {
        return (
            <div>
                <Button id="pie-settings-btn" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
                    Open form dialog
                </Button>
                    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="pie-settings-dialog">
                        <DialogContent>  
                            <Tabs defaultTab="pie-settings" horizontal>
                                <TabList className="row">
                                    <Tab className="col-md-4" tabFor="pie-settings">Pie Settings</Tab>
                                    <Tab className="col-md-4" tabFor="integrations">Integrations</Tab>
                                    <Tab className="col-md-4" tabFor="pie-profile">Pie Profile</Tab>
                                </TabList>
                                <TabPanel tabId="pie-settings">
                                    <div className="tab-content">
                                        <div className="tab-pane active">
                                            <form onSubmit={this.handlePieSettings}>
                                                <div className="form-group" >
                                                    <label className="col-lg-2 control-label text-semibold" >Pie Name <span className="requiredFields">*</span></label>
                                                    <div className="col-lg-10" style={{marginBottom: "2vh"}}>
                                                        <input type="text" className="form-control" name="pname" placeholder="Pie Name" required onchange={this.handleInput} />
                                                    </div>
                                                </div>
                                                {/* <div class="form-group">
                                                    <label class="col-lg-2 control-label text-semibold">Logo</label>
                                                    <div class="col-lg-10" style={{marginTop: "1vh"}}>
                                                        <div class="media no-margin-top">
                                                            <div class="media-left">
                                                                <img src={user_2} style={{width: "58px", height: "58px"}} class="img-rounded" alt="" id="logo_img" />
                                                                <br />
                                                                <center><a href="#">Remove</a></center>
                                                            </div>
                                                            <div class="media-body">
                                                                <div class="uploader">
                                                                    <input type="file" class="file-styled" onChange={this.handleUploadImg} />
                                                                    <span class="filename" id="filename" >No file selected</span>
                                                                    <span class="action btn btn-danger" >Choose File</span>
                                                                </div>
                                                                <span class="help-block">Accepted formats: gif, png, jpg. Max file size 2Mb. Must be 64x64 of dimensions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row form-group">
                                                    <div className="col-sm-4">
                                                        <label className="text-semibold">Currency</label>
                                                        <select className="form-control" name="currency" id="currency" onChange={this.handleInput}>
                                                            <option value={"AFN"}>Afghanistan Afghani</option>
                                                            <option value={"ALL"}>Albania Lek</option>
                                                            <option value={"DZD"}>Algeria Dinar</option>
                                                            <option value={"AOA"}>Angola Kwanza</option>
                                                            <option value={"ARS"}>Argentina Peso</option
                                                            ><option value={"AMD"}>Armenia Dram</option>
                                                            <option value={"AWG"}>Aruba Guilder</option>
                                                            <option value={"AUD"}>Australia Dollar</option>
                                                            <option value={"AZN"}>Azerbaijan New Manat</option>
                                                            <option value={"BSD"}>Bahamas Dollar</option>
                                                            <option value={"BHD"}>Bahrain Dinar</option>
                                                            <option value={"BDT"}>Bangladesh Taka</option>
                                                            <option value={"BBD"}>Barbados Dollar</option>
                                                            <option value={"BYR"}>Belarus Ruble</option>
                                                            <option value={"BZD"}>Belize Dollar</option>
                                                            <option value={"BMD"}>Bermuda Dollar</option>
                                                            <option value={"BTN"}>Bhutan Ngultrum</option>
                                                            <option value={"BOB"}>Bolivia Bolíviano</option>
                                                            <option value={"BAM"}>Bosnia and Herzegovina Convertible Marka</option>
                                                            <option value={"BWP"}>Botswana Pula</option>
                                                            <option value={"BRL"}>Brazil Real</option>
                                                            <option value={"BND"}>Brunei Darussalam Dollar</option>
                                                            <option value={"BGN"}>Bulgaria Lev</option>
                                                            <option value={"BIF"}>Burundi Franc</option>
                                                            <option value={"KHR"}>Cambodia Riel</option>
                                                            <option value={"CAD"}>Canada Dollar</option>
                                                            <option value={"CVE"}>Cape Verde Escudo</option>
                                                            <option value={"KYD"}>Cayman Islands Dollar</option>
                                                            <option value={"CLP"}>Chile Peso</option>
                                                            <option value={"CNY"}>China Yuan Renminbi</option>
                                                            <option value={"COP"}>Colombia Peso</option>
                                                            <option value={"XOF"}>Communauté Financière Africaine (BCEAO) Franc</option>
                                                            <option value={"XAF"}>Communauté Financière Africaine (BEAC) CFA Franc BEAC</option>
                                                            <option value={"KMF"}>Comoros Franc</option>
                                                            <option value={"XPF"}>Comptoirs Français du Pacifique (CFP) Franc</option>
                                                            <option value={"CDF"}>Congo/Kinshasa Franc</option>
                                                            <option value={"CRC"}>Costa Rica Colon</option>
                                                            <option value={"HRK"}>Croatia Kuna</option>
                                                            <option value={"CUC"}>Cuba Convertible Peso</option>
                                                            <option value={"CUP"}>Cuba Peso</option>
                                                            <option value={"CZK"}>Czech Republic Koruna</option>
                                                            <option value={"DKK"}>Denmark Krone</option>
                                                            <option value={"DJF"}>Djibouti Franc</option>
                                                            <option value={"DOP"}>Dominican Republic Peso</option>
                                                            <option value={"XCD"}>East Caribbean Dollar</option>
                                                            <option value={"EGP"}>Egypt Pound</option>
                                                            <option value={"SVC"}>El Salvador Colon</option>
                                                            <option value={"ERN"}>Eritrea Nakfa</option>
                                                            <option value={"ETB"}>Ethiopia Birr</option>
                                                            <option value={"EUR"}>Euro Member Countries</option>
                                                            <option value={"FKP"}>Falkland Islands (Malvinas) Pound</option>
                                                            <option value={"FJD"}>Fiji Dollar</option>
                                                            <option value={"GMD"}>Gambia Dalasi</option>
                                                            <option value={"GEL"}>Georgia Lari</option>
                                                            <option value={"GHS"}>Ghana Cedi</option>
                                                            <option value={"GIP"}>Gibraltar Pound</option>
                                                            <option value={"GTQ"}>Guatemala Quetzal</option>
                                                            <option value={"GGP"}>Guernsey Pound</option>
                                                            <option value={"GNF"}>Guinea Franc</option>
                                                            <option value={"GYD"}>Guyana Dollar</option>
                                                            <option value={"HTG"}>Haiti Gourde</option>
                                                            <option value={"HNL"}>Honduras Lempira</option>
                                                            <option value={"HKD"}>Hong Kong Dollar</option>
                                                            <option value={"HUF"}>Hungary Forint</option>
                                                            <option value={"ISK"}>Iceland Krona</option>
                                                            <option value={"INR"}>India Rupee</option>
                                                            <option value={"IDR"}>Indonesia Rupiah</option>
                                                            <option value={"XDR"}>International Monetary Fund (IMF) Special Drawing Rights</option>
                                                            <option value={"IRR"}>Iran Rial</option>
                                                            <option value={"IQD"}>Iraq Dinar</option>
                                                            <option value={"IMP"}>Isle of Man Pound</option>
                                                            <option value={"ILS"}>Israel Shekel</option>
                                                            <option value={"JMD"}>Jamaica Dollar</option>
                                                            <option value={"JPY"}>Japan Yen</option>
                                                            <option value={"JEP"}>Jersey Pound</option>
                                                            <option value={"JOD"}>Jordan Dinar</option>
                                                            <option value={"KZT"}>Kazakhstan Tenge</option>
                                                            <option value={"KES"}>Kenya Shilling</option>
                                                            <option value={"KPW"}>Korea (North) Won</option>
                                                            <option value={"KRW"}>Korea (South) Won</option>
                                                            <option value={"KWD"}>Kuwait Dinar</option>
                                                            <option value={"KGS"}>Kyrgyzstan Som</option>
                                                            <option value={"LAK"}>Laos Kip</option>
                                                            <option value={"LBP"}>Lebanon Pound</option>
                                                            <option value={"LSL"}>Lesotho Loti</option>
                                                            <option value={"LRD"}>Liberia Dollar</option>
                                                            <option value={"LYD"}>Libya Dinar</option>
                                                            <option value={"MOP"}>Macau Pataca</option>
                                                            <option value={"MKD"}>Macedonia Denar</option>
                                                            <option value={"MGA"}>Madagascar Ariary</option>
                                                            <option value={"MWK"}>Malawi Kwacha</option>
                                                            <option value={"MYR"}>Malaysia Ringgit</option>
                                                            <option value={"MVR"}>Maldives (Maldive Islands) Rufiyaa</option>
                                                            <option value={"MRO"}>Mauritania Ouguiya</option>
                                                            <option value={"MUR"}>Mauritius Rupee</option>
                                                            <option value={"MXN"}>Mexico Peso</option>
                                                            <option value={"MDL"}>Moldova Leu</option>
                                                            <option value={"MNT"}>Mongolia Tughrik</option>
                                                            <option value={"MAD"}>Morocco Dirham</option>
                                                            <option value={"MZN"}>Mozambique Metical</option>
                                                            <option value={"MMK"}>Myanmar (Burma) Kyat</option>
                                                            <option value={"NAD"}>Namibia Dollar</option>
                                                            <option value={"NPR"}>Nepal Rupee</option>
                                                            <option value={"ANG"}>Netherlands Antilles Guilder</option>
                                                            <option value={"NZD"}>New Zealand Dollar</option>
                                                            <option value={"NIO"}>Nicaragua Cordoba</option>
                                                            <option value={"NGN"}>Nigeria Naira</option>
                                                            <option value={"NOK"}>Norway Krone</option>
                                                            <option value={"OMR"}>Oman Rial</option>
                                                            <option value={"PKR"}>Pakistan Rupee</option>
                                                            <option value={"PAB"}>Panama Balboa</option>
                                                            <option value={"PGK"}>Papua New Guinea Kina</option>
                                                            <option value={"PYG"}>Paraguay Guarani</option>
                                                            <option value={"PEN"}>Peru Sol</option>
                                                            <option value={"PHP"}>Philippines Peso</option>
                                                            <option value={"PLN"}>Poland Zloty</option>
                                                            <option value={"QAR"}>Qatar Riyal</option>
                                                            <option value={"RON"}>Romania New Leu</option>
                                                            <option value={"RUB"}>Russia Ruble</option>
                                                            <option value={"RWF"}>Rwanda Franc</option>
                                                            <option value={"SHP"}>Saint Helena Pound</option>
                                                            <option value={"WST"}>Samoa Tala</option>
                                                            <option value={"SAR"}>Saudi Arabia Riyal</option>
                                                            <option value={"SPL"}>Seborga Luigino</option>
                                                            <option value={"RSD"}>Serbia Dinar</option>
                                                            <option value={"SCR"}>Seychelles Rupee</option>
                                                            <option value={"SLL"}>Sierra Leone Leone</option>
                                                            <option value={"SGD"}>Singapore Dollar</option>
                                                            <option value={"SBD"}>Solomon Islands Dollar</option>
                                                            <option value={"SOS"}>Somalia Shilling</option>
                                                            <option value={"ZAR"}>South Africa Rand</option>
                                                            <option value={"LKR"}>Sri Lanka Rupee</option>
                                                            <option value={"SDG"}>Sudan Pound</option>
                                                            <option value={"SRD"}>Suriname Dollar</option>
                                                            <option value={"SZL"}>Swaziland Lilangeni</option>
                                                            <option value={"SEK"}>Sweden Krona</option>
                                                            <option value={"CHF"}>Switzerland Franc</option>
                                                            <option value={"SYP"}>Syria Pound</option>
                                                            <option value={"STD"}>São Tomé and Príncipe Dobra</option>
                                                            <option value={"TWD"}>Taiwan New Dollar</option>
                                                            <option value={"TJS"}>Tajikistan Somoni</option>
                                                            <option value={"TZS"}>Tanzania Shilling</option>
                                                            <option value={"THB"}>Thailand Baht</option>
                                                            <option value={"TOP"}>Tonga Pa'anga</option>
                                                            <option value={"TTD"}>Trinidad and Tobago Dollar</option>
                                                            <option value={"TND"}>Tunisia Dinar</option>
                                                            <option value={"TRY"}>Turkey Lira</option>
                                                            <option value={"TMT"}>Turkmenistan Manat</option>
                                                            <option value={"TVD"}>Tuvalu Dollar</option>
                                                            <option value={"UGX"}>Uganda Shilling</option>
                                                            <option value={"UAH"}>Ukraine Hryvnia</option>
                                                            <option value={"AED"}>United Arab Emirates Dirham</option>
                                                            <option value={"GBP"}>United Kingdom Pound</option>
                                                            <option value={"USD"}>United States Dollar</option>
                                                            <option value={"UYU"}>Uruguay Peso</option>
                                                            <option value={"UZS"}>Uzbekistan Som</option>
                                                            <option value={"VUV"}>Vanuatu Vatu</option>
                                                            <option value={"VEF"}>Venezuela Bolivar</option>
                                                            <option value={"VND"}>Viet Nam Dong</option>
                                                            <option value={"YER"}>Yemen Rial</option>
                                                            <option value={"ZMW"}>Zambia Kwacha</option>
                                                            <option value={"ZWD"}>Zimbabwe Dollar</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label className="text-semibold">Non Cash Multiplier</label>
                                                        <input type="number" className="form-control" name="noncashx" onChange={this.handleInput} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label className="text-semibold">Cash Multiplier</label>
                                                        <input type="number" className="form-control" name="cashx" onChange={this.handleInput} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-6">
                                                        <label className="text-semibold">Commission Rate</label>
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <Slider 
                                                                    min={0} 
                                                                    max={100} 
                                                                    step={1}
                                                                    name="comrate"
                                                                    onChange={this.handleCommissionRate}
                                                                />
                                                            </div>
                                                            <div classNam="col-sm-2">
                                                                <p className="slider-val">{this.state.comrate + "%"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="text-semibold">Royalty Rate</label>
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <Slider 
                                                                    min={0} 
                                                                    max={100} 
                                                                    step={1}
                                                                    name="rorate"
                                                                    onChange={this.handleRoyaltyRate}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="slider-val">{this.state.rorate + "%"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-6">
                                                        <label className="text-semibold">Investors Finder's Fee</label>
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <Slider 
                                                                    min={0} 
                                                                    max={100} 
                                                                    step={1}
                                                                    name="Apct"
                                                                    onChange={this.handleApct}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="slider-val">{this.state.Apct + "%"}</p>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="form-group">
                                                            <label className="col-lg-4 control-label">For up to raised</label>
                                                            <div className="col-lg-8">
                                                                <input type="number" className="form-control" name="A" onChange={this.handleInput} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>&nbsp;</label>
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <Slider 
                                                                    min={0} 
                                                                    max={100} 
                                                                    step={1}
                                                                    name="Bpct"
                                                                    onChange={this.handleBpct}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="slider-val">{this.state.Bpct + "%"}</p>
                                                            </div>
                                                        after that
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <label className="text-semibold">Personal Car Settings</label><i class="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                                        <select className="select form-control" id="personal_car_settings" onChange={this.selectPersonalCarSettings} name="car">
                                                            <option value={0}>Disable Personal Car Settings</option>
                                                            <option value={1}>Treat as Cash</option>
                                                            <option value={2}>Treat as Non-Cash</option>
                                                            <option value={3}>Split Rate</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row form-group el-hide" id="metric-unit-row">
                                                    <div className="col-sm-12">
                                                        <label className="text-semibold">Metric Unit</label>
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" name="stacked-radio-left" className="styled" name="unit" value={"mi"} onChange={this.handleMetricUnit} />
                                                                Miles
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" name="stacked-radio-left" className="styled" name="unit" value={"km"} onChange={this.handleMetricUnit} />
                                                                Kilometers
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12" style={{marginTop: "10px"}}>
                                                        <label className="text-semibold">Per mi reimbursement rate</label>
                                                        <input type="text" className="form-control" name="reimbrate" onChange={this.handleInput} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-6">
                                                        <label className="checkbox-inline text-semibold">
                                                            <input type="checkbox" className="styled" id="enable_overtime" name="overtime_enable" value={1} onChange={this.checkOvertime}  />
                                                            Enable Overtime
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row form-group" id="overtime-fields-row">
                                                    <div className="col-sm-6">
                                                        <label className="text-semibold">Overtime Hours per Week</label>
                                                        <input type="number" className="form-control" name="overtime_hours" onChange={this.handleInput} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="text-semibold">Overtime Hours Multiplier</label>
                                                        <input type="number" className="form-control" name="overtime_multiplier" onChange={this.handleInput} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <label className="checkbox-inline text-semibold">
                                                            <input type="checkbox" className="styled" name="email_alerts" value={1} onChange={this.handleEmailAlerts} />
                                                            Email Alerts
                                                        </label>
                                                        <i class="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-semibold">Projects</label> 
                                                    <ReactChip 
                                                        onChange={this.handleProjects}
                                                        placeholder="Add Project (Hit enter to add project)"
                                                        name="tags"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <button type="button" className="btn btn-danger pull-right" onClick={this.handleClose} >
                                                        <i className="icon-cross"></i> Cancel
                                                    </button>
                                                    <button type="button" className="btn btn-danger pull-right" onClick={this.deletePie} style={{marginRight: "5px"}} id="btn-delete-pie">
                                                        <i className="icon-trash-alt"></i> Delete Pie
                                                    </button>
                                                    <button type="button" className="btn btn-danger pull-right" onClick={this.resetPie} style={{marginRight: "5px"}} id="btn-reset-pie">
                                                        <i className="icon-rotate-ccw3"></i> Reset Pie
                                                    </button>
                                                    <button type="submit" className="btn btn-danger pull-left">
                                                        <i className="icon-check"></i> Save Changes
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel tabId="integrations">
                                    <p>Tab 2 content</p>
                                </TabPanel>
                                <TabPanel tabId="pie-profile">
                                    <div className="tab-pane" style={{marginTop: "2vh"}}>
                                        <div class="alert alert-warning ">
                                            If you post Gruntwork [hover: Jobs], your Pie Profile will be included in the listing to help potential Grunts learn more about your company.
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label className="col-lg-2 control-label text-semibold">Logo</label>
                                                <div className="col-lg-10">
                                                    <div class="media no-margin-top">
                                                        <div class="media-left">
                                                            <img src={user_2} style={{width: "58px", height: "58px"}} class="img-rounded" alt="" id="logo_img" />
                                                            <br />
                                                            <center><a href="#">Remove</a></center>
                                                        </div>
                                                        <div class="media-body">
                                                            <div class="uploader">
                                                                <input type="file" class="file-styled" onChange={this.handleUploadImg} />
                                                                <span class="filename" id="filename" >No file selected</span>
                                                                <span class="action btn btn-danger" >Choose File</span>
                                                            </div>
                                                            <span class="help-block">Accepted formats: gif, png, jpg. Max file size 2Mb. Must be 64x64 of dimensions</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label className="text-semibold">Mission/Vision Statement</label>
                                                    <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                                    <textarea className="form-control" id="mission_vision"></textarea>
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                            <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchMission}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= {this.state.labelMission}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label className="text-semibold">Company Summary</label>
                                                    <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                                    <textarea className="form-control" id="company-summary"></textarea>
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                            <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= {this.state.labelSummary}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label className="text-semibold">Location</label>
                                                    <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                                    <input type="text" class="form-control" id="location" />
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                            <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchLocation}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= {this.state.labelLocation}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label className="text-semibold">Links</label> 
                                                    <i className="icon-info22 helper-icon" title="HELPER"></i>
                                                </div>
                                                <div className="col-md-10">
                                                    <input type="text" className="form-control" placeholder="LinkedIn" />
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Enable"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10" style={{marginTop: "10px"}}>
                                                    <input type="text" className="form-control" placeholder="Twitter" />
                                                </div>
                                                <div className="col-md-2" style={{marginTop: "10px"}}>
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Enable"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10" style={{marginTop: "10px"}}>
                                                    <input type="text" className="form-control" placeholder="Facebook" />
                                                </div>
                                                <div className="col-md-2" style={{marginTop: "10px"}}>
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Enable"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12" style={{marginTop: "10px"}}>
                                                    <button type="button" className="btn btn-danger btn-xs">
                                                        <i className="icon-plus3"></i>
                                                        Add Another
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Show links to existing Grunt profiles"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Show Pie chart and number of Slices"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                <   div className="checkbox checkbox-switchery switchery-xs">
                                                        <Switch
                                                            className="switch-class"
                                                            onChange={this.handleSwitchSummary}
                                                            options={
                                                                {
                                                                    color: '#64BC63',
                                                                    size: 'small',
                                                                }
                                                            }
                                                            label= "Show Pie settings"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-danger pull-right">
                                                    <i className="icon-cross"></i>
                                                    Cancel
                                                </button>
                                                <button type="button" className="btn btn-danger pull-right" style={{marginRight: "5px"}}>
                                                    <i className="icon-file-text2"></i>
                                                    Preview
                                                </button>
                                                <button type="button" className="btn btn-danger pull-left">
                                                    <i className="icon-check"></i>
                                                    Save Changes
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </TabPanel>
                            </Tabs>                          
                        </DialogContent>
                            <DialogActions>
                                <Button id="cancel_btn" onClick={this.handleClose} color="primary" style={{display: "none"}}>Cancel</Button>
                            </DialogActions>
                    </Dialog>
                    <ResetPie />
                    <DeletePie />
            </div>
        );
    }
}

export default PieSettingsComponent;