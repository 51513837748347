import React from 'react';
import Inactive from './Team/InactiveTeam';
import ActiveTeam from './Team/ActiveTeam';
import Well from './Well/WellValue';
import PieSlices from './Well/PieSlices';
import ReactApexChart from 'react-apexcharts'
import CoolTabs from 'react-cool-tabs';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { MenuItem, Select, FormControl } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddFund from '../components/Well/AddFunds';
import DrawFund from '../components/Well/DrawFunds';
import logo from '../assets/img/logo.png';
import AddTeamMember from './AddTeamMember';
import EmailTheTeam from './EmailTheTeam';
import PieSettingsComponent from './PieSettingsComponent';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';
import DeletePie from './DeletePie';

  let url = '"https://api.thepieslicer.com"';

  class WellValue extends React.Component {
    render() {
      return(
        <div>
          <Well />
        </div>
      );
    }
  }

  class TeamInActive extends React.Component {
    render() {
      return(
        <div>
          <Inactive />
        </div>
      );
    }
  }

    
    export default class Home extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          results: [],
          pie_ID: localStorage.getItem('def_id'),
          img_src: "",
          pie_name: "",
          // jobTitles: []
        }
      }

    componentDidMount(){
      document.title = "Slicing Pie - Pie Slicer";

      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var pNames = [];
      var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }
    
        var handle = this;  
        apiHandler.push(
        "https://api.thepieslicer.com/api/pie/" + userID,
        "GET",
        null,
        function(response) {
        if(response.status === 200) {
            var data = response.data;
            var pie_IDs = [];
            var pId = null;
            // console.log(data);

            for(var i = 0; i < data.length; i++){
              pie_IDs.push(data[i].fund_id);
              pId = pie_IDs.join();
              localStorage.setItem('def_id', data[0].fund_id)
            }

            handle.getPieInfo(pId);
            
        }  
        else {
            swal({
            title: "Please check the details and try again.",
            type: "error"
            }).then(result => {
            });
        }
        }, headers, true
        );
    }

  getPieInfo = (id) => {

    var token = localStorage.getItem('token');
    var userID = localStorage.getItem('userID');
    var apiHandler = new ApiHandler();

    var headers = {
      Authorization: "Bearer " + token
      }

      var handle = this;  
      apiHandler.push("getpie","POST",{
        id: userID,
        pie_id: id
      },function(response) {
        if(response.status === 200) {
          var data = response.data;
          
          handle.setState({results: data});
          var sel = document.getElementById('select_team');
          handle.setState( {pie_name: sel.options[0].text} );
        }

        else {
            swal({
            title: "Please check your information!",
            type: "error"
            }).then(result => {
            });
        }
      }, headers
      );

  }

  addTeamMember = () => {
      document.getElementById("add-team-member").click();
  }
  emailTheTeam = () => {
      document.getElementById("email-the-team").click();
  }

  pieSettings = () => {
      document.getElementById("pie-settings-btn").click();
  }

  handleTeamMember = (evt) => {
    localStorage.setItem('aff', evt.target.value);
    this.setState({pie_ID: evt.target.value});
    var select = document.getElementById('select_team');
      this.setState( {pie_name: select.options[select.selectedIndex].text} );
  }


    render() {
        return(
          <div>
            <div className="page-header">
                <div className="page-header-content">
                    <div className="page-title">
                        <h4 className="pie-name-heading">
                            <input type="hidden" value={this.state.img_src} />
                            <span style={{marginRight: "7px"}}><img src={logo} style={{width: "58px", height: "58px"}} className="img-rounded" alt="" /></span>
                            <span className="text-semibold header-pie-name">
                              
                              {/* MosquitOasis <i className="icon-arrow-down5 change-pie"></i> */}
                                
                              <select
                              onChange={this.handleTeamMember}
                              name="name"
                              id="select_team"
                              >
                              {this.state.results.map(function(item, i){
                                try {
                                  // return <li key={i}>{item.funds["id"]}</li>
                                 return <option value={item.funds["id"]}>{item.funds["name"]}</option>
                                } catch (error) {
                                }
                              })}
                              </select>
                              </span>
                        </h4>
                    </div>

                    <div className="heading-elements">
                        <div className="heading-btn-group">
                            <a className="btn btn-link btn-float has-text" onClick={this.addTeamMember}><i className="icon-user-plus text-primary"></i><span>ADD TEAM MEMBER</span></a>
                            <a className="btn btn-link btn-float has-text" onClick={this.emailTheTeam}><i className="icon-envelope text-primary"></i> <span>EMAIL THE TEAM</span></a>
                            <a className="btn btn-link btn-float has-text" onClick={this.pieSettings}><i className="icon-cog text-primary"></i> <span>PIE SETTINGS</span></a>
                            <AddTeamMember />
                            <EmailTheTeam />
                            <PieSettingsComponent />
                        </div>
                    </div>
                </div>
	        </div>
            <div className="page-container">
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="row" style={{marginBottom: "20px"}}>
                            <div className="col-md-7">
                            <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className="panel-title" style={{fontSize: "14px"}}>The Team</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails id="home_expansion_panel">
                                    <Typography style={{width: "100%", fontSize: "12px"}}>
                                        <CoolTabs
                                        tabKey={'1'}
                                        style={{ width:  480, height:  '100%', background:  'transparent' }}
                                        activeTabStyle={{ backgroundColor: 'transparent', color:  'black' }}
                                        unActiveTabStyle={{ backgroundColor:  'transparent', color:  'black' }}
                                        activeLeftTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                        activeRightTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                        leftTabTitle={'Active'}
                                        rightTabTitle={'Inactive'}
                                        leftContent={<ActiveTeam updateID={this.state.pie_ID}/>}
                                        rightContent={<TeamInActive/>} />
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </div>
                            <div className="col-md-5">
                                <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography className="panel-title" style={{fontSize: "14px"}}>The Well</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Typography style={{width: "100%", fontSize: "12px"}}>
                                        <CoolTabs
                                        tabKey={'1'}
                                        style={{ width:  480, height:  500, background:  'transparent' }}
                                        activeTabStyle={{ backgroundColor: 'transparent', color:  'black' }}
                                        unActiveTabStyle={{ backgroundColor:  'transparent', color:  'black' }}
                                        activeLeftTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                        activeRightTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                        leftTabTitle={'Pie Slices'}
                                        rightTabTitle={'Well Value'}
                                        leftContent={<PieSlices updateID={this.state.pie_ID}/>}
                                        rightContent={<WellValue/>} />
                                    </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeletePie pieName={this.state.pie_name} />
            </div>
        );
    }
}