import React from 'react';
import logo from '../assets/img/logo-centered.png';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';
import Footer from './Footer';
import { BrowserRouter as Router, Link } from "react-router-dom";


export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          password: ""
        };
      }

    componentDidMount(){
    document.title = "Pie Name - Pie Slicer";
    }

    login = event => {
    event.preventDefault();
    let apiHandler = new ApiHandler();

        apiHandler.push(
        "login",
        "POST",
        {
            email: this.state.email,
            password: this.state.password,
        
        },
        function(response) {
        console.log(response);
            if(response.status === 200) {
                if (response.data.success.token) {
                    localStorage.setItem("token", response.data.success.token);
                    localStorage.setItem("userID", response.data.success.user.id);
                    localStorage.setItem("name", response.data.success.user.first_name + " " +  response.data.success.user.last_name);
                    localStorage.setItem("email", response.data.success.user.email);
                    localStorage.setItem("imgSrc", response.data.success.user.Image);
                    window.location.href="/";
                }
            }  
            else {
                swal({
                title: "Invalid Login",
                type: "error"
                }).then(result => {
                });
            }
        }
        );
    };

    handleChange = event => {
    this.setState({
        [event.target.id]: event.target.value
    });
    };

    signupPage = () => {
        window.location.href = '/signup';
    }

    forgotPage = () => {
        window.location.href = '/forgot-password';
    }


      render() {
          return(
        <div>
            <div className="login-container">
                <div className="page-container">
                    <div className="page-content">
                            <div className="content-wrapper">
                                <center>
                                    <img src={logo} id="login-logo" alt="img-logo"/>
                                </center>
                                    <form onSubmit={this.login} style={{marginTop: "2vh"}}>
                                        <div className="panel panel-body login-form">
                                            <div>
                                                <h5 className="content-group" style={{textAlign: "center"}}>Welcome Back, Fellow Grunt!</h5>
                                            </div>
                                            <div className="form-group has-feedback has-feedback-left">
                                                <input type="text" id="email" className="form-control" placeholder="Email Address" autoFocus value={this.state.email} onChange={this.handleChange} />
                                                <div className="form-control-feedback">
                                                    <i className="fa fa-user text-muted" />
                                                </div>
                                            </div>
                                            <div className="form-group has-feedback has-feedback-left">
                                                <input type="password" id="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                                                <div className="form-control-feedback">
                                                    <i className="fa fa-lock text-muted"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled"/>
                                                                Remember Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <Router>
                                                        <div className="col-md-6">
                                                            {/* <a href="#" className="pull-right">Forgot Password</a> */}
                                                            <Link to="/forgot" className="pull-right" onClick={this.forgotPage}>Forgot Password</Link>
                                                        </div>
                                                    </Router>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-danger btn-block">Sign in to your account</button>
                                            </div>
                                            <Router>
                                                <div className="text-center">
                                                    <Link to="/signup" onClick={this.signupPage}>Don't have an account? Sign Up</Link>
                                                </div>
                                            </Router>
                                        </div>
                                    </form>
                            </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
          );
      }
}
