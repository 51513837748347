import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../../utils/apiHandler';
import "../../assets/css/customize.css";
import {getCurrentDate} from '../../utils/getCurrentDate';
import Otherspopup from './Otherspopup';

export default class Others extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          openInfo: false,
          type: null,
          repeat: 0,
          date: getCurrentDate(),
          aff: 11162,
          pie_id: null,
          user_id: localStorage.getItem('userID'),
          description: "",
          amount: null,
          name: localStorage.getItem('name')
        };
      }

    
    handleClickOpen = () => {
        this.setState({open: true});
    }

    infoClick = () => {
        document.getElementById('others-popup').click();
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      });
    };

    handleRadio = event => {
      var type_value = event.target.value;
        if(type_value === "cash"){
          this.setState({
            [event.target.name]: 8
          });
        }
        else{
          this.setState({
            [event.target.id]: 9
          });
        }
    }

    componentDidMount() {

      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var apiHandler = new ApiHandler();
        
      var headers = {
      Authorization: "Bearer " + token
      }
  
      var handle = this;  
      apiHandler.push(
      "https://api.thepieslicer.com/api/pie/" + userID,
      "GET",
      null,
      function(response) {
      if(response.status === 200) {
          var data = response.data;
          console.log(data);
          for(var i = 0; i < data.length; i++){
            handle.setState({pie_id: data[i].fund_id});
          }
      }  
      else {
          swal({
          title: "Please check the details and try again.",
          type: "error"
          }).then(result => {
          });
      }
      }, headers, true
      );
      
    }

    handleSubmit = (event) => {
      var token = localStorage.getItem('token');
      event.preventDefault();
      let apiHandler = new ApiHandler();

      var headers = {
        Authorization: "Bearer " + token
      }

      apiHandler.push(
      "contribute",
      "POST",
      {
          type: this.state.type,
          date: this.state.date,
          aff: this.state.aff,
          pie_id: this.state.pie_id,
          user_id: this.state.user_id,
          description: this.state.description,
          amount: this.state.amount,
      },
      function(response) {
      console.log(response);
      if(response.status === 200) {
          // valid
              localStorage.removeItem('pie_name');
              swal({
              title: "Other Contribution Added Successfully!",
              type: "success"
              }).then(result => {
                window.location.href="/";
              });
      }  
      else {
          swal({
          title: "Please check the details and try again.",
          type: "error"
          }).then(result => {
          });
      }
      }, headers
      );
    }

    render() {
        return(
            <div>
            <Button id="contrib-others" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="sales-modal">
              <DialogTitle id="form-dialog-title">
              <button type="button" className="close" onClick={this.infoClick}>
                  <i className="icon-info22 helper-icon" data-popup="popover" data-placement="bottom" title="HELPER" data-html="true"></i>
              </button>
                <h5 class="modal-title"><i class="icon-more"></i> &nbsp;Other</h5>
              </DialogTitle>
              <DialogContent>
                <div className="alert alert-warning ">
	                Adding contribution for <strong>{this.state.name}</strong>
	            </div>

                <div className="form-group">
					<label>Date <span className="requiredFields">*</span></label>
					<div className="input-group">
						<span className="input-group-addon"><i className="icon-calendar2"></i></span>
						<input type="text" className="form-control pickadate-limits" placeholder="Pick a date" value={getCurrentDate()} />
					</div>
				</div>
                
        <div className="form-group">
					<label>Description</label>
					<textarea className="form-control" id="description" onChange={this.handleChange} value={this.state.description} ></textarea>
				</div>

        <div className="form-group">
					<label>Amount <span className="requiredFields">*</span></label>
					<input type="text" className="form-control" id="amount" onChange={this.handleChange} value={this.state.amount} />
				</div>

                <div className="form-group">
					<div className="radio" style={{marginTop: "5px !important"}}><label><input type="radio" name="type" className="styled" value={"cash"} onChange={this.handleRadio} />Cash</label></div>
					<div className="radio" style={{marginTop: "5px !important"}}><label><input type="radio" name="type" className="styled" value={"non-cash"} onChange={this.handleRadio} />Non-Cash</label></div>
				</div>

				<div className="form-group">
					<label className="display-block text-semibold">Repeat Contribution</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" checked="checked" />Off</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Daily</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Weekly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Monthly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Annually</label>
				</div>
              </DialogContent>
              <DialogActions>
                <button className="btn btn-danger pull-right" data-dismiss="modal" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
				        <button className="btn btn-danger pull-left" onClick={this.handleSubmit}><i className="icon-check"></i> Calculate Slices</button>
              </DialogActions>
            </Dialog>
            <Otherspopup />
          </div>
        );
    }
}


