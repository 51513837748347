import React from 'react';
import HomeFooter from './HomeFooter';
import logo from '../assets/img/logo.png';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';
import '../assets/css/customize.css';


let clone = {};
export default class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            summaryResults: []
        };
    }

    componentDidMount() {
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
        
        var headers = {
        Authorization: "Bearer " + token
        }
    
            var handle = this;  
            apiHandler.push(
            "https://api.thepieslicer.com/api/pie/" + userID,
            "GET",
            null,
            function(response) {
                if(response.status === 200) {
                    var data = response.data;
                    var pie_IDs = [];
                    var pId = null;
                    var firstID = data[0].fund_id;

                    for(var i = 0; i < data.length; i++){
                    pie_IDs.push(data[i].fund_id);
                    pId = pie_IDs.join();
                    }

                    handle.getSummaryData(firstID);

                    handle.getPieInfo(pId);
                    
                }  
                else {
                    swal({
                    title: "Please check the details and try again.",
                    type: "error"
                    }).then(result => {
                    });
                }
            }, headers, true
            );
    }

    getPieInfo = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
    
        var headers = {
          Authorization: "Bearer " + token
          }
    
          var handle = this;  
          apiHandler.push("getpie","POST",{
            id: userID,
            pie_id: id
          },function(response) {
            if(response.status === 200) {
              var data = response.data;
              
              handle.setState({results: data});
    
            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
    
      }


    getSummaryData = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
    
        var headers = {
          Authorization: "Bearer " + token
        }
    
          var handle = this;  
          apiHandler.push("summary","POST",{
            user_id: userID,
            pie_id: id
          },function(response) {
            if(response.status === 200) {
                var data = response.data;

                var arr_data = [];
                for(var x = 0; x < data.data.length; x++){
                    var test = (({ name, pct, ...o }) => o)(data.data[x]);
                        var summed = 0;
                        for (var key in test) {
                            summed += test[key];
                            arr_data.push(summed);
                            clone = {data: arr_data};
                        };
                }

                console.log(clone);

                for (let key in clone) {
                    var storage = [];
                    storage += clone[key];

                }
                    handle.setState({summaryResults: data.data});

            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
    }

    handlePieChange = (evt) => {
        var id = evt.target.value;
        this.getSummaryData(id)
    }


    render() {
        return (
            <div className="layout-boxed navbar-top">
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{ marginRight: "7px" }}><img src={logo} style={{ width: "58px", height: "58px" }} className="img-rounded" alt="" /></span>
                                {/* <span className="text-semibold header-pie-name">MosquitOasis <i className="icon-arrow-down5 change-pie"></i></span> */}

                                    <select
                                    onChange={this.handlePieChange}
                                    name="name"
                                    id="select_team"
                                    >
                                        {this.state.results.map(function(item, i){
                                            try {
                                                return <option  value={item.funds['id']}>{item.funds["name"]}</option>
                                            } 
                                            catch (error) {
                                            }
                                        })}

                                    </select>


                            </h4>
                        </div>
                    </div>
                </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel panel-flat">
                                        <table className="table_custom_summary">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "500px !important"}} className="td_nowrap">Team Member</th>
                                                    <th>Time</th>
                                                    <th>Cash</th>
                                                    <th className="td_nowrap">Supplies and Equipment</th>
                                                    <th>Facilities</th>
                                                    <th className="td_nowrap">Intellectual Property</th>
                                                    <th>Commissions</th>
                                                    <th>Non-Cash</th>
                                                    <th className="td_nowrap">Personal Car</th>
                                                    <th className="td_nowrap">Lump-Sum</th>
                                                    <th>Total</th>
                                                    <th className="td_nowrap">Slice of the Pie</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            { this.state.summaryResults.map((item, num) => {
                                                // console.log(item);
                                                return <tr>
                                                <td nowrap style={{textAlign:"left"}}>
                                                {/* style={{color: "#bf0000", borderColor: "#bf00000"}} */}
                                                    <span className="grunt-name td_nowrap" >{item['name']}</span>
                                                </td>
                                                <td align="right">{item['time']}</td>
                                                <td align="right">{item['cash']}</td>
                                                <td align="right" className="td_nowrap">{item['supplies']}</td>
                                                <td align="right">{item['facilities']}</td>
                                                <td align="right" className="td_nowrap">{item['ip']}</td>
                                                <td align="right">{item['commissions']}</td>
                                                <td align="right">{item['noncash']}</td>
                                                <td align="right" className="td_nowrap">{item['car']}</td>
                                                <td align="right" className="td_nowrap">{item['lumpsum']}</td>
                                                <td align="right">34,000</td>
                                                <td align="right" className="td_nowrap">{item['pct']}</td>
                                            </tr>

                                            })} 

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </div>
        );
    }

}