import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import "../../assets/css/customize.css";

export default class Facilitypopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
        };
      }

    handleClickOpen = () => {
        this.setState({open: true});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    render() {
        return(
        <div>
            <Button id="facility-popup" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="info" style={{marginLeft: "29.3vw"}}>
            <DialogContent>
                <div className="arrow_box">
                Time is the most common contribution people make to startup companies. The Pie Slicer records time contributions in hours and performs the following calculation:
                </div>
            </DialogContent>
            {/* <DialogActions>
            </DialogActions> */}
            </Dialog>
        </div>
        );
    }
    


}
