import React, { Component } from 'react';
import AddFund from './AddFunds';
import DrawFund from './DrawFunds';
import ReactApexChart from 'react-apexcharts';

class WellValue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
              plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                vertical: true,
                dataLabels: {
                  position: 'left'
                },
              }
            },
            colors: ['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff']
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
  
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: ['Mike' , 'Mong' , 'Jayson' , 'Kier' , 'Mat'],
            },
            yaxis: {
              labels: {
                show: true
              }
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: true
              },
              y: {
                title: {
                  formatter: function () {
                    return 'Well Value: '
                  }
                }
              }
            }
            },
            series: [{
              data: [600, 450, 220, 120, 360]
            }],
          }

    }

    addFund = () => {
        document.getElementById('well-add-fund').click();
    }

    drawFund = () => {
        document.getElementById('well-draw-fund').click();
    }
    
    render() {
        return <div className="tab-pane" id="well-value">
            <div className="panel panel-flat">
                <div className="panel-body">
                    <div style={{width: "100px", display: "inline-block"}}>
                        <span className="chart-label">WELL VALUE (USD)</span>
                    </div>
                    <span className="well-value">345</span>
                    <div className="pull-right">
                        <div style={{width: "65px", display: "inline-block", verticalAlign: "top"}}>
                            <span className="chart-label">MANAGE FUNDS</span>
                        </div>
                        <div className="manage-funds">
                            <span>
                                <i className="icon-plus-circle2 manage-funds-icon" id="btn-add-funds-well" data-popup="tooltip" title="Add Funds to Well" data-container="body" onClick={this.addFund}></i>
                                <i className="icon-minus-circle2 manage-funds-icon" id="btn-draw-funds-well" data-popup="tooltip" title="Draw Funds from Well" data-container="body" onClick={this.drawFund}></i>
                            </span>
                        </div>
                    </div>
                    <div className="chart-container">
                        {/* <div className="chart has-fixed-height" id="columns_basic"></div> */}
                        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="350" />
                    </div>
                </div>
            </div>
            <AddFund />
            <DrawFund />
        </div>
    }
}

export default WellValue;