import React from 'react';

export default class HomeFooter extends React.Component {
    render() {
        return(
            <div className="footer footer-boxed text-muted" style={{textAlign: "center"}}>
                <ul className="foot-links">
                    <li className="pull-left">
                        <a target="_blank" href="#">Privacy Policy</a>
                        <span className="margleft">|</span>
                    </li>
                    <li className="pull-left margleft">
                        <a target="_blank" href="#">Terms of Service</a>
                        <span className="margleft">|</span>
                    </li>
                    <li className="pull-left margleft">
                        <a target="_blank" href="#">Credits</a>
                    </li>
                </ul><br />
		        <span style={{color: "#696969"}}>&copy; {(new Date().getFullYear())}. Lake Shark Ventures, LLC</span>
	        </div>
        );
    }
}