import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';


export default class EmailTheTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        subject: "",
        message: "",
        open: false,
        pie_id: localStorage.getItem('aff')
        };
      }

    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };


        handleSubmit = event => {

            var token = localStorage.getItem('token');
            var userID = localStorage.getItem('userID');
            event.preventDefault();
            let apiHandler = new ApiHandler();

            var headers = {
            Authorization: "Bearer " + token
            }

            apiHandler.push(
            "email",
            "POST",
            {
                pie_id: this.state.pie_id,
                user_id: userID,
                subject: this.state.subject,
                message: this.state.message,
                
            },
            function(response) {
            console.log(response);
            if(response.status === 200) {
                // valid
                document.getElementById('hidden_btn').click();
                localStorage.removeItem('pie_name');
                swal({
                    title: "Email Successfully Sent!",
                    type: "success"
                }).then(result => {
                    window.location.href="/";
                });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );
        }

        render() {
        return(
            <div>
            <Button id="email-the-team" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
                Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><i className="icon-envelope"></i> &nbsp;Email the Team</DialogTitle>
                <DialogContent>
                <form onSubmit={this.handleSubmit}>
                    <div className="alert alert-warning ">
                        Email the team will only email team members who have logged in at least once.<br />Your message will not be saved in the Pie Slicer.
                    </div>
                    <div className="form-group">
                        <label>Subject <span className="requiredFields">*</span></label>
                        <input type="text" className="form-control" name="subject" id="subject" value={this.state.subject} onChange={this.handleChange} required />
                    </div>

                    <div className="form-group">
                        <label>Message <span className="requiredFields">*</span></label>
                        <textarea className="form-control" rows="6" name="message" id="message" value={this.state.message} onChange={this.handleChange} required></textarea>
                    </div>
                    <button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Send Message</button>
                    <button id="close_btn" className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                    <input type="hidden" id="hidden_btn" onClick={this.handleClose} />
                </form>
                </DialogContent>
                {/* <DialogActions>
                <button className="btn btn-danger pull-left" onClick={this.handleSubmit}><i className="icon-check"></i> Send Message</button>
                <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                </DialogActions> */}
            </Dialog>
            </div>
        );
    }

}