import React from 'react';
import CreateNewPie from './CreateNewPie';
import Routes from './Routes';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';


    class HomeCreatePie extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
              pie_id: null
            }
          }

    componentDidMount() {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
            
        var headers = {
        Authorization: "Bearer " + token
        }
    
        var handle = this;  
        apiHandler.push(
        "https://api.thepieslicer.com/api/pie/" + userID,
        "GET",
        null,
        function(response) {
        if(response.status === 200) {
            var data = response.data;
            // console.log(data);
            for(var i = 0; i < data.length; i++){
                handle.setState({pie_id: data[i].fund_id});
            }
        }  
        else {
            swal({
            title: "Please check the details and try again.",
            type: "error"
            }).then(result => {
            });
        }
        }, headers, true
        );
    
        }

        render() {
          var page = (this.state.pie_id) ? (<Routes />) : (<CreateNewPie />);
          return page;
        }
      }
      
      export default HomeCreatePie;
      