import React, { Component } from 'react';
import { render } from 'react-dom';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import "react-web-tabs/dist/react-web-tabs.css";
import '../assets/css/customize.css';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';

let url = "https://api.thepieslicer.com";
var arrayLength = 0;
let status = [];
let sub_plan = [];
let subs_price = [];
let paid_through = [];
let next_bill = [];
let payment_method = [];
let fund_id = [];

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {

            image: "",
            prof_name: "",
            firstname: "",
            lastname: "",
            address: "",
            city: "",
            country: "",
            state: "",
            zip_code: "",
            telephone: "",
            arrayLength: 0,
            status: [],
            sub_plan: [],
            subs_price: [],
            paid_through: [],
            next_bill: [],
            payment_method: [],
            created: [],
            pie_name: [],
            fund_id: [],
            old_pwd: "",
            new_pwd: "",
            confirm_pwd: "",
            contributions: 0,
            weekly_summary: 0, 
            well: 0,
            daily_summary: 0
        }
    }

    componentDidMount() {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
        
        var headers = {
        Authorization: "Bearer " + token
        }
    
            var handle = this;  
            apiHandler.push(
            "https://api.thepieslicer.com/api/pie/" + userID,
            "GET",
            null,
            function(response) {
                if(response.status === 200) {
                    var data = response.data;
                    var pie_IDs = [];
                    var pId = null;
                    var firstID = data[0].fund_id;

                    for(var i = 0; i < data.length; i++){
                    pie_IDs.push(data[i].fund_id);
                    pId = pie_IDs.join();
                    }

                    handle.getSubscriptionInfo(pId);
                    handle.getPieInfo(pId);
                    handle.getUserInfo(userID);

                }  
                else {
                    swal({
                    title: "Please check the details and try again.",
                    type: "error"
                    }).then(result => {
                    });
                }
            }, headers, true
            );
    }

    getPieInfo = (pie_IDS) => {
        
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

        var handle = this;  
        apiHandler.push("getpie","POST",{
            id: userID,
            pie_id: pie_IDS
        },function(response) {
            if(response.status === 200) {
            var data = response.data;
            var created = [];
            var pname = [];
            handle.setState({results: data});

            for(var x = 0; x < data.length; x++) {
                created.push(data[x].funds.createdAt);
                pname.push(data[x].funds.name);
            }

            handle.setState(
                {
                    created: created,
                    pie_name: pname
                }
            );

            }

            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
        }, headers
        );

    }

    getSubscriptionInfo = (pie_IDS) => {
        var token = localStorage.getItem('token');
        var apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            var handle = this;  
            apiHandler.push("subscription","POST",{
                id: pie_IDS,
            },function(response) {
                if(response.status === 200) {
                var data = response.data;
                // console.log(data);
                handle.setState( {arrayLength: data.length} );
                for(var x = 0; x < data.length; x++) {
                    for(var y = 0; y <= x; y++) {
                        try {
                            
                            next_bill.push(data[x].data[y].nextBillingDate);
                            paid_through.push(data[x].data[y].paidThroughDate);
                            payment_method.push(data[x].data[y].payment_method);
                            sub_plan.push(data[x].data[y].plan);
                            subs_price.push(data[x].data[y].price);
                            status.push(data[x].data[y].subscription_status);
                            fund_id.push(data[x].data[y].fund_id);
                        
                        } catch (error) {
                        
                        }
                    }
                    handle.setState(
                        {
                            status: status,
                            next_bill: next_bill,
                            paid_through: paid_through,
                            payment_method: payment_method,
                            sub_plan: sub_plan,
                            subs_price: subs_price,
                            fund_id: fund_id

                        }
                    );
                }

                }

                else {
                    swal({
                    title: "Please check your information!",
                    type: "error"
                    }).then(result => {
                    });
                }
            }, headers
            );
    }

    getUserInfo = (id) => {
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }
    
            var handle = this;  
            apiHandler.push(
            "https://api.thepieslicer.com/api/user/" + id,
            "GET",
            null,
            function(response) {
            if(response.status === 200) {
                var data = response.data;
                handle.setState(
                    {
                        image: data.Image,
                        firstname: data.first_name,
                        lastname: data.last_name,
                        address: data.address,
                        city: data.city,
                        country: data.country,
                        state: data.state,
                        zip_code: data.zip,
                        telephone: data.phone
                    }
                )
                
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers, true
            );
    }

    deletePie = (event) => {
        console.log(event.target.value);
    }
    
    
    onImageChange = (event) => {

        var reader = new FileReader();
        var file = event.target.files[0];
        var self = this;

            reader.onload = function(upload) {
              self.setState( {image: upload.target.result} );
            };
            reader.readAsDataURL(file);
    }
    
    handleInput = (evt) => {
        this.setState( {[evt.target.name]: evt.target.value} );
    }

    handleImageUpload = (event) => {

        var token = localStorage.getItem('token');
        event.preventDefault();
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "upload",
            "POST",
            {
                name: this.state.prof_name,
                image: this.state.image,
            },
            function(response) {
            if(response.status === 200) {
                // valid
                    localStorage.removeItem('pie_name');
                    swal({
                    title: "Image Uploaded Successfully!",
                    type: "success"
                    }).then(result => {
                    window.location.reload();
                    });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );

    }

    BillingInfo = (event) => {

        var token = localStorage.getItem('token');
        event.preventDefault();
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "billing",
            "PUT",
            {
                
                user_id: localStorage.getItem('userID'),
                fname: this.state.firstname,
                lname: this.state.lastname,
                address: this.state.address,
                city: this.state.city,
                country: this.state.country,
                state: this.state.state,
                zip: this.state.zip_code,
                phone: this.state.telephone
            
            },
            function(response) {
            if(response.status === 200) {
                // valid
                    localStorage.removeItem('pie_name');
                    swal({
                    title: "Billing Info Updated Successfully!",
                    type: "success"
                    }).then(result => {
                    window.location.reload();
                    });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );
    }

    handleChangePassword = (event) => {
        var token = localStorage.getItem('token');
        event.preventDefault();
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "account",
            "POST",
            {
                
                action: "profile",
                name: localStorage.getItem('name'),
                user_id: localStorage.getItem('userID'),
                oldpw: this.state.old_pwd,
                newpw: this.state.new_pwd,
                newpw2: this.state.confirm_pwd
            
            },
            function(response) {
            if(response.status === 200) {
                // valid
                    localStorage.removeItem('pie_name');
                    swal({
                    title: "Password updated Successfully!",
                    type: "success"
                    }).then(result => {
                    window.location.reload();
                    });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );
    }

    handleCheck = (evt) => {

        if(document.getElementById('contributions').checked) {
            this.setState( {contributions: evt.target.value} );
         }
     
         if(document.getElementById('weekly_summary').checked) {
            this.setState( {weekly_summary: evt.target.value} );
         }
     
         if(document.getElementById('well').checked) {
            this.setState( {well: evt.target.value} );
         }
     
         if(document.getElementById('daily_summary').checked) {
            this.setState( {daily_summary: evt.target.value} );
         }
    }

    handleNotifications = (event) => {

        var token = localStorage.getItem('token');
        event.preventDefault();
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "alerts",
            "PUT",
            {
                
                user_id: localStorage.getItem('userID'),
                contributions: this.state.contributions,
                well: this.state.well,
                weekly_summary: this.state.weekly_summary,
                daily_summary: this.state.daily_summary

            },
            function(response) {
            if(response.status === 200) {
                // valid
                    localStorage.removeItem('pie_name');
                    swal({
                    title: "Notifications Info Updated Successfully!",
                    type: "success"
                    }).then(result => {
                    window.location.reload();
                    });
            }  
            else {
                swal({
                title: "Please check the details and try again.",
                type: "error"
                }).then(result => {
                });
            }
            }, headers
            );
    }


    render() {
        return (
            <body className="layout-boxed navbar-top has-detached-left">
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                    <Tabs defaultTab="account" vertical className="vertical-tabs" id="vertical_tabs">
                                        <div className="col-md-3" style={{padding:"24px"}}>
                                            <div className="panel-body text-center" id="panel-body-text">
                                                <div className="content-group-sm">
                                                    <h6 className="text-semibold no-margin-bottom">
                                                        {localStorage.getItem('name')}
                                                    </h6>
                                                    <span className="display-block">CEO</span>
                                                </div>
                                                <a className="display-inline-block content-group-sm">
                                                    <img src={url + this.state.image} className="img-circle img-responsive" alt="" style={{width: "110px", height: "110px"}} />
                                                </a>
                                            <TabList>
                                                <Tab tabFor="account">
                                                    <i className="icon-user"></i>
                                                    <span>Account</span>
                                                </Tab>
                                                <Tab tabFor="billing-info">
                                                    <i className="icon-credit-card"></i>
                                                    <span>Billing Info</span>
                                                </Tab>
                                                <Tab tabFor="my-pies">
                                                    <i className="icon-pie-chart2"></i>
                                                    <span>My Pies</span>
                                                </Tab>
                                                <Tab tabFor="change-email">
                                                    <i className="icon-envelop3"></i>
                                                    <span>Change Email</span>
                                                </Tab>
                                                <Tab tabFor="change-password">
                                                    <i className="icon-lock2"></i>
                                                    <span>Change Password</span>
                                                </Tab>
                                                <Tab tabFor="notifications">
                                                    <i className="icon-bell3"></i>
                                                    <span>Notifications</span>
                                                </Tab>
                                            </TabList>
                                            </div>
                                        </div>
                                        <div className="col-md-9" id="account_tabpanel">
                                            <TabPanel tabId="account">
                                                <div className="tab-pane active" id="profile">
                                                    <div className="panel panel-flat">
                                                        <div className="panel-heading">
                                                            <h5 className="panel-title">Account</h5>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="alert alert-warning">
                                                                These are your global settings. Pie-specific settings (like salary) can only be changed by the Pie owner.
                                                            </div>
                                                            <fieldset>
                                                                <legend>
                                                                    <h6 className="panel-title">
                                                                        Profile
                                                                    </h6>
                                                                </legend>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <label className="label_font">Public Name</label>
                                                                            <input type="text" id="prof_firstname" className="form-control" name="prof_name" placeholder="Public Name" onChange={this.handleInput} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group" id="upload_imgDiv">
                                                                    <label className="label_font">Profile Image</label>
                                                                    <input type="file" className="file-styled" name="myImage" onChange={this.onImageChange} />
                                                                    <span className="help-block">Accepted formats: gif, png, jpg. Max file size 2Mb</span>
                                                                </div>
                                                                <div className="text-left">
                                                                    <button className="btn btn-danger" id="btnsaveprofile" onClick={this.handleImageUpload}>Save Changes</button>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel tabId="billing-info">
                                                <div className="panel panel-flat">
                                                    <div className="panel-heading">
                                                        <h6 className="panel-title">Billing Info</h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">First Name</label>
                                                                    <input type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Last Name</label>
                                                                    <input type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Address</label>
                                                                    <input type="text" className="form-control" name="address" value={this.state.address} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">City</label>
                                                                    <input type="text" className="form-control" name="city" value={this.state.city} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Country</label>
                                                                    <input type="text" className="form-control" name="country" value={this.state.country} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">State</label>
                                                                    <input type="text" className="form-control" name="state" value={this.state.state} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Zip Code</label>
                                                                    <input type="text" className="form-control" name="zip_code" value={this.state.zip_code} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Telephone</label>
                                                                    <input type="text" className="form-control" name="telephone" value={this.state.telephone} onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-left">
                                                            <button type="button" className="btn btn-danger" id="btnsaveprofile" onClick={this.BillingInfo}>Save Changes</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel tabId="my-pies" id="mypie_tab">
                                                <div className="panel panel-flat">
                                                    <div className="panel-heading">
                                                        <h5 className="panel-title">My Pies</h5>
                                                    </div>
                                                    <div className="panel-body">
                                                        <table className="table table-hover table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Pie Name</th>
                                                                    <th>Status</th>
                                                                    <th>Created On</th>
                                                                    <th>Plan</th>
                                                                    <th>Price</th>
                                                                    <th>Paid Through</th>
                                                                    <th>Next Billing Date</th>
                                                                    <th>Payment Method</th>
                                                                    <th></th>
                                                                    </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.from(Array(this.state.arrayLength), (item, ind) => {
                                                                    return <tr>
                                                                        <td>{this.state.pie_name[ind]}</td>
                                                                        <td>{this.state.status[ind]}</td>
                                                                        <td>{this.state.created[ind]}</td>
                                                                        <td>{this.state.sub_plan[ind]}</td>
                                                                        <td>{this.state.subs_price[ind]}</td>
                                                                        <td>{this.state.paid_through[ind]}</td>
                                                                        <td>{this.state.next_bill[ind]}</td>
                                                                        <td>{this.state.payment_method[ind]}</td>
                                                                        <td><button type="button" className="btn btn-danger" value={this.state.fund_id[ind]} onClick={this.deletePie}>Reset/Delet Pie</button></td>
                                                                    </tr>
                                                                })
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel tabId="change-email">
                                                <div className="panel panel-flat">
                                                    <div className="panel-heading">
                                                        <h5 className="panel-title">Change Email</h5>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">New Email Address</label>
                                                                    <input type="email" className="form-control" name="email_address" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Enter Password</label>
                                                                    <input type="password" className="form-control" name="password" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-left">
                                                            <button type="button" className="btn btn-danger">Change Email Address</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel tabId="change-password">
                                                <div className="panel panel-flat">
                                                    <div className="panel-heading">
                                                        <h5 className="panel-title">Change Password</h5>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Old Password</label>
                                                                    <input type="password" className="form-control" name="old_pwd" onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">New Password</label>
                                                                    <input type="password" className="form-control" name="new_pwd" onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="label_font">Confirm New Password</label>
                                                                    <input type="password" className="form-control" name="confirm_pwd" onChange={this.handleInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-left">
                                                            <button type="button" className="btn btn-danger" onClick={this.handleChangePassword}>Update Password</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel tabId="notifications">
                                                <div className="panel panel-flat">
                                                    <div className="panel-heading">
                                                        <h5 className="panel-title">Notifications</h5>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="checkbox check_div">
                                                                    <label className="text-semibold">
                                                                        <input type="checkbox" name="stacked-checkbox-left" className="styled" value={1} id="contributions" onChange={this.handleCheck}  />
                                                                        Email me for new contributions
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox check_div">
                                                                    <label className="text-semibold">
                                                                        <input type="checkbox" name="stacked-checkbox-left" className="styled" value={1} id="well" onChange={this.handleCheck}  />
                                                                        Email me when funds from the well have been used
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox check_div">
                                                                    <label className="text-semibold">
                                                                        <input type="checkbox" name="stacked-checkbox-left" className="styled" value={1} id="weekly_summary" onChange={this.handleCheck}  />
                                                                        Send me a weekly summary
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox check_div">
                                                                    <label className="text-semibold">
                                                                        <input type="checkbox" name="stacked-checkbox-left" className="styled" value={1} id="daily_summary" onChange={this.handleCheck}  />
                                                                        Send me a daily summary
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <button type="button" className="btn btn-danger" onClick={this.handleNotifications} id="notifSave">Save Changes</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default AccountSettings;