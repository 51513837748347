import React, { Component } from 'react';

class InactiveTeam extends Component {
    render() {
        return  <div class="tab-pane" id="member-inactive">
                Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid laeggin.
                </div>
    }
}

export default InactiveTeam;