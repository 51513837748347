import React from 'react';
import Navbar from './Navbar';
import HomeFooter from './HomeFooter';
import Contributions from './Contributions';
import Analytics from './Analytics';
import Home from './Home';
import Lumpsum from './Lumpsum';
import Summary from './Summary';
import Well from './Well';
import FindGruntWork from './Bakery/FindGruntWork';
import FindGrunts from './Bakery/FindGrunts';
import Gruntworks from './Bakery/Gruntworks';
import AccountSettings from './AccountSettings';
import { BrowserRouter } from 'react-router-dom';
import { Route } from "react-router";


export default class Routes extends React.Component {

    render() {
        return(
            <div className="layout-boxed navbar-top">
        <BrowserRouter>
            <Navbar />
                <Route exact path="/" component={Home} />
                <Route path="/analytics" component={Analytics} />
                <Route path="/contributions" component={Contributions} />
                <Route path="/lumpsum" component={Lumpsum} />
                <Route path="/summary" component={Summary} />
                <Route path="/well" component={Well} />
                <Route path="/account" component={AccountSettings} />
                <Route path="/find_gruntwork" component={FindGruntWork} />
                <Route path="/find_grunts" component={FindGrunts} />
                <Route path="/gruntworks" component={Gruntworks} />
            <HomeFooter />
        </BrowserRouter>
            </div>
        );
    }
}
