import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import {getCurrentDate} from '../utils/getCurrentDate';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';

class TeamSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            jobtitle: "",
            restriction: 0,
            salary: "",
            account_type: "",
            lumpamount: "",
            lumpdescription: "",


        }
        
    }

    handleClickOpen = () => {
        this.setState( {open: true} );
    }

    handleClose = () => {
        this.setState( {open: false} );
    }

    handleInput = (event) => {
        this.setState( {
            [event.target.name]: event.target.value
        } );
    }

    handleRestriction = () => {
        var radio = document.querySelector('input[name="restriction"]:checked').value;
        this.setState( {restriction: radio} );
    }

    saveSettings = (event) => {
        event.preventDefault();
        var token = localStorage.getItem('token');
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "team",
            "PUT",
            {
                
                jobtitle: this.state.jobtitle,
                salary: this.state.salary,
                account_type: this.state.account_type, 
                restriction: this.state.restriction,
                overtime_eligibility: 5,
                aff_id: 11162,
                pie_id: localStorage.getItem('aff'),
                user_id: localStorage.getItem('userID'),
                name: localStorage.getItem('name'),
                email: localStorage.getItem('email')

            },
                function(response) {
                if(response.status === 200) {
                    // valid
                        document.getElementById('cancel_btn').click();
                        localStorage.removeItem('pie_name');
                        swal({
                        title: "Pie Settings Updated Successfully!",
                        type: "success"
                        }).then(result => {
                        window.location.href="/";
                        });
                }  
                else {
                    swal({
                    title: "Please check the details and try again.",
                    type: "error"
                    }).then(result => {
                    });
                }
                }, headers
            );
    }

    saveLumpsum = (event) => {
        event.preventDefault();
        var token = localStorage.getItem('token');
        let apiHandler = new ApiHandler();

        var headers = {
        Authorization: "Bearer " + token
        }

            apiHandler.push(
            "lumpsum",
            "POST",
            {
                
                aff_id: 11162,
                pie_id: localStorage.getItem('aff'),
                user_id: localStorage.getItem('userID'),
                lumpamount: this.state.lumpamount,
                lumpdescription: this.state.lumpdescription

            },
                function(response) {
                console.log(response);
                if(response.status === 200) {
                    // valid
                        document.getElementById('cancel_btn').click();
                        localStorage.removeItem('pie_name');
                        swal({
                        title: "Pie Settings Updated Successfully!",
                        type: "success"
                        }).then(result => {
                        window.location.reload();
                        });
                }  
                else {
                    swal({
                    title: "Please check the details and try again.",
                    type: "error"
                    }).then(result => {
                    });
                }
                }, headers
            );
    }

    
    render() {
        return (
            <div>
                <Button id="team-settings" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
                    Open form dialog
                </Button>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <h5 class="modal-title">
                            <i class="icon-cog"></i>
                            &nbsp;Settings: {localStorage.getItem('name')} ({localStorage.getItem('email')})
                        </h5>
                    </DialogTitle>
                    <DialogContent>
                        <Tabs defaultTab="settings" horizontal>
                            <TabList className="row">
                                <Tab className="col-md-4" tabFor="settings">Settings</Tab>
                                <Tab className="col-md-4" tabFor="lumpsum-payment">Lump-sum Payment</Tab>
                                <Tab className="col-md-4" tabFor="remove-terminate">Remove/Terminate</Tab>
                            </TabList>
                            <TabPanel tabId="settings">
                                <div className="tab-pane active">
                                    <form onSubmit={this.saveSettings} id="settings-tab-form">
                                        <div className="form-group">
                                            <label>Job Title<span className="requiredFields">*</span></label>
                                            <input type="text" className="form-control" name="jobtitle" id="jobtitle" required onChange={this.handleInput} />
                                        </div>
                                        <div className="form-group">
                                            <label>Fair Market Salary<span className="requiredFields">*</span></label>
                                            <input type="text" className="form-control" name="salary" id="salary" required onChange={this.handleInput} />
                                        </div>
                                        <div className="form-group">
                                            <label>Account Type <span className="requiredFields">*</span></label>
                                            <select id="cars" className="form-control" id="settings_account_type" name="account_type" required onChange={this.handleInput} >
                                                <option value="">Choose Account Type</option>
                                                <option value={1}>Owner</option>
                                                <option value={2}>Executive</option>
                                                <option value={3}>Adviser</option>
                                                <option value={4}>Employee</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="display-block text-semibold">Restriction</label>
                                            <label className="radio-inline">
                                                    <input type="radio" name="restriction" className="styled" value={0} onChange={this.handleRestriction} />
                                                None
                                            </label>
                                            <label className="radio-inline">
                                                    <input type="radio" name="restriction" className="styled" value={1} onChange={this.handleRestriction} />
                                                Contributions Require Approval
                                            </label>
                                            <label className="radio-inline">
                                                    <input type="radio" name="restriction" className="styled" value={2} onChange={this.handleRestriction} />
                                                View Only
                                            </label>
                                        </div>
                                        <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                                        <button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Save Changes</button>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel tabId="lumpsum-payment">
                                <div className="tab-pane">
                                    <form id="lumpsum-payment-tab-form" onSubmit={this.saveLumpsum}>
                                        <p>
                                            When you make a lump-sum payment, the Pie Slicer will reduce the number of slices this person contributed starting with cash contributions, then their Well balance, then non-cash contributions.
                                        </p>
                                        <div className="form-group">
                                            <label>Date<span className="requiredFields">*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="icon-calendar2"></i></span>
                                                <input type="text" className="form-control pickadate-limits" placeholder="Pick a date" value={getCurrentDate()} readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" id="lumpsum_description" name="lumpdescription" onChange={this.handleInput}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Lump-Sum Payment Amount<span className="requiredFields">*</span></label>
                                            <input type="number" className="form-control" required id="lumpsum_payment" name="lumpamount" min="0" onChange={this.handleInput} />
                                        </div>
                                        <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                                        <button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Reduces Slices</button>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel tabId="remove-terminate">
                                <div className="tab-pane">
                                    <div className="form-group row" style={{display: "block"}}>
                                        <div className="remove-term-radio">
                                            <label>
                                                <input type="radio" name="stacked-radio-left" className="styled" />
                                                Terminate for Good Reason
                                            </label> 
                                            <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                        </div>
                                        <div className="remove-term-radio">
                                            <label>
                                                <input type="radio" name="stacked-radio-left" className="styled" />
                                                Terminate for No Good Reason
                                            </label> 
                                            <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                        </div>
                                        <div className="remove-term-radio">
                                            <label>
                                                <input type="radio" name="stacked-radio-left" className="styled" />
                                                Resign for Good Reason
                                            </label> 
                                            <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                        </div>
                                        <div className="remove-term-radio">
                                            <label>
                                                <input type="radio" name="stacked-radio-left" className="styled" />
                                                Resign for No Good Reason
                                            </label> 
                                            <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
                                        </div>
                                    </div>
                                    <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                                    <button className="btn btn-danger pull-left"><i className="icon-check"></i> Remove Team Member</button>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </DialogContent>
                    <DialogActions>
                        <Button id="cancel_btn" onClick={this.handleClose} color="primary" style={{display: "none"}}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary" style={{display: "none"}}>
                            Subscribe
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default TeamSettings;