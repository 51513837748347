import React from 'react';
import Navbar from './Navbar';
import HomeFooter from './HomeFooter';
import { BrowserRouter as Router } from "react-router-dom";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';

let token = localStorage.getItem('token');

export default class CreateNewPie extends React.Component {
    instance;

    constructor(props) {
        super(props);
        this.state = {
          plan: "",
          piename: "",
          salary: "",
          grunt_leader: null,
        //   jobtitle: "Employee",
        //   hourly_rate: 100,
        //   color: "red",
          subscription_id: "23b5q6",
        //   status: "Active",
        //   price: 500,
        //   paidThroughDate: "2019-12-01",
        //   nextBillingDate: "2019-12-12",
        //   payment_method: null,
          payment_method_token: null,
        //   payment_method_image: "https://assets.braintreegateway.com/payment_method_logo/paypal.png?environment=production",
        //   on_braintree: 1,

        };
      }


    handleChange = (input, type) => {
        this.setState({plan: input});
    }

    handleInput = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };



    

    componentDidMount(){
        document.title = "Slicing Pie - Pie Slicer";
        var user_id = localStorage.getItem('userID');
        this.setState({grunt_leader: user_id});
        
        let apiHandler = new ApiHandler();
        var headers = {
            Authorization: "Bearer " + token
        }

        // var handle = this;
        apiHandler.push("token", "POST" , {id: user_id},function(response) {
            // console.log(response);
            if(response.status === 200) {
                var dropin = require('braintree-web-drop-in');
                dropin.create({
                  authorization: response.data.token,
                  container: '#dropin'
                }, function (createErr, instance) {
                    document.getElementById("btn-bt-create").addEventListener('click', function () {
                        instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
                            console.log(payload);
                            // var nonce = payload.nonce;
                            // var payment_method = payload.details.cardType;
                            // handle.setState({payment_method_token: nonce});
                            document.getElementById('submit-btn').click();
                        });
                    });

                });
            }

            else {
                swal({
                title: "Please check again!",
                type: "error"
                }).then(result => {
                });
            }

            }, headers
        );                
    }

    handleSubmit = () => {
        console.log(this.state);
        var data = this.state;

        let apiHandler = new ApiHandler();
        var headers = {
            Authorization: "Bearer " + token
        }

        apiHandler.push("pie","POST",{

            grunt_leader: data.grunt_leader,
            payment_method_token: data.payment_method_token,
            piename: data.piename,
            plan: data.plan,
            salary: data.salary,
            subscription_id: data.subscription_id

            },function(response) {
            console.log(response);
            if(response.status === 200) {
                localStorage.removeItem('pie_name');
                swal({
                    title: "Pie Created Successfully!",
                    type: "success"
                    }).then(result => {
                        window.location.href = '/';
                });
            }

            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }

            }, headers
        );
    }

    render() {
        return(
            <Router>
                <div className="layout-boxed navbar-top">
                    <Navbar />
                    <div className="page-container">
                        <div className="page-content">
                            <div className="content-wrapper">
                                <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography className="panel-title" style={{fontSize: "14px"}}>Create New Pie</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Typography style={{width: "100%", fontSize: "12px"}}>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group has-feedback">
                                                    <select className="form-control" required id="plan" onChange={(e) => this.handleChange(e.target.value, "plan")}>
                                                        <option value="">Select Plan</option>
                                                        <option value="Monthly">Subscription: Monthly ($19.95), 14-days FREE</option>
                                                        <option value="Annual">Subscription: Annual ($199.95), 14-days FREE</option>
                                                        <option value="Tri-Annual">Subscription: Tri-Annual ($299.95), 14-days FREE</option>
                                                    </select>
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control" placeholder="Pie Name" required autoFocus id="piename" value={this.state.piename} onChange={this.handleInput} />
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <input type="text" className="form-control pull-left" placeholder="Your Fair Market Salary Less Cash Compensation" required style={{width: "95%"}} id="salary" value={this.state.salary} onChange={this.handleInput} />
                                                    <span><a href="https://slicingpie.com/pie-slicer-user-guide/#Creating-a-New-Pie" target="_blank"><i className="icon-question4" style={{paddingTop: "10px", paddingLeft: "10px", cursor: "pointer", color: "#000"}}></i></a></span>
                                                </div>
                                                <div className="text-left" style={{marginTop: "30px"}}>
                                                    <h5 className="content-group">Payment Options</h5>
                                                </div>
                                                <div id="dropin">
                                                </div>
                                                <button id="btn-bt-create" className="btn btn-danger">Submit</button>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>Each Pie is a separate subscription.</p>
                                                <p>You may have as many contributors as you want for each Pie.</p>
                                                <p>You may cancel your subscription by deleting the Pie.</p>
                                                <p><a href="https://slicingpie.com/pie-slicer-user-guide/#Creating-a-New-Pie" target="_blank">Learn more about creating a new Pie</a></p>
                                            </div>
                                        </div>
                                    </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <input type="button" value="Submit" style={{display: "none"}} id="submit-btn" onClick={this.handleSubmit} />
                            </div>
                        </div>
                    </div>
                    <HomeFooter />
                </div>
            </Router>
        );
    }
}