import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../../utils/apiHandler';
import "../../assets/css/customize.css";
import {getCurrentDate} from '../../utils/getCurrentDate';
import Facilitypopup from './Facilitypopup';

export default class Facilities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          openInfo: false,
          type: 6,
          repeat: 0,
          date: getCurrentDate(),
          reimbursed: "",
          aff: "",
          pie_id: localStorage.getItem('def_id'),
          user_id: localStorage.getItem('userID'),
          description: "",
          amount: null,
          name: localStorage.getItem('name')
        };
      }

    
    handleClickOpen = () => {
        this.setState({open: true});
    }

    infoClick = () => {
        document.getElementById('facility-popup').click();
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      });
    };

    componentDidMount() {
        
      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var apiHandler = new ApiHandler();
      var pieID = localStorage.getItem('def_id');
        
        if(localStorage.getItem('aff')){
          pieID = localStorage.getItem('aff');
          this.setState( {pie_id: localStorage.getItem('aff')} );
        }


      var headers = {
        Authorization: "Bearer " + token
      }

      var handle = this;  
        apiHandler.push("getpie","POST",{
          id: userID,
          pie_id: pieID
        },function(response) {
          if(response.status === 200) {
            var data = response.data;
            var IDS = [];
              
              for(var a = 0; a < data[0].contributors.length; a++) {
                  IDS.push(data[0].contributors[a].id);
              }

              handle.getAffiliateID(IDS);

          }

          else {
              swal({
              title: "Please check your information!",
              type: "error"
              }).then(result => {
              });
          }
        }, headers
        );
    }

    getAffiliateID = (id) => {

      var arrID = id;
      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var apiHandler = new ApiHandler();
        
      var headers = {
      Authorization: "Bearer " + token
      }
  
      var handle = this;  
      apiHandler.push(
      "https://api.thepieslicer.com/api/pie/" + userID,
      "GET",
      null,
      function(response) {
      if(response.status === 200) {
          var data = response.data;
          
          for(var x = 0; x < data.length; x++) {
            if ( arrID.includes(data[x].id) ){
              handle.setState( {aff: data[x].id} );
            }
          }
          
      }  
      else {
          swal({
          title: "Please check the details and try again.",
          type: "error"
          }).then(result => {
          });
      }
      }, headers, true
      );
    }

    handleSubmit = (event) => {
      var token = localStorage.getItem('token');
      event.preventDefault();
      let apiHandler = new ApiHandler();

      var headers = {
        Authorization: "Bearer " + token
      }

      apiHandler.push(
      "contribute",
      "POST",
      {
          type: this.state.type,
          date: this.state.date,
          reimbursed: this.state.reimbursed,
          aff: this.state.aff,
          pie_id: this.state.pie_id,
          user_id: this.state.user_id,
          description: this.state.description,
          amount: this.state.amount,
          
      },
      function(response) {
      console.log(response);
      if(response.status === 200) {
          // valid
              document.getElementById('cancel_btn').click();
              localStorage.removeItem('pie_name');
              swal({
              title: "Facilities Contribution Added Successfully!",
              type: "success"
              }).then(result => {
                window.location.href="/";
              });
      }  
      else {
          swal({
          title: "Please check the details and try again.",
          type: "error"
          }).then(result => {
          });
      }
      }, headers
      );
    }


    render() {
        return(
            <div>
            <Button id="contrib-facilities" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="sales-modal">
              <DialogTitle id="form-dialog-title">
              <button type="button" className="close" onClick={this.infoClick}>
                  <i className="icon-info22 helper-icon" data-popup="popover" data-placement="bottom" title="HELPER" data-html="true"></i>
              </button>
                    <h5 className="modal-title"><i className="icon-office"></i> &nbsp;Facilities</h5>
              </DialogTitle>
              <DialogContent>
                <div className="alert alert-warning ">
	                Adding contribution for <strong>{this.state.name}</strong>
	            </div>
                <div className="form-group">
					<label>Date <span className="requiredFields">*</span></label>
					<div className="input-group">
						<span className="input-group-addon"><i className="icon-calendar2"></i></span>
						<input type="text" className="form-control pickadate-limits" placeholder="Pick a date" value={getCurrentDate()} />
					</div>
				</div>
                <div className="form-group">
					<label>Description</label>
					<textarea className="form-control" id="description" onChange={this.handleChange} value={this.state.description} ></textarea>
				</div>

                <div className="form-group">
					<label>Fair Market Value of Facility <span className="requiredFields">*</span></label>
					<input type="text" className="form-control" id="amount" onChange={this.handleChange} value={this.state.amount} />
				</div>

                <div className="form-group">
					<label>Cash Payment Received (optional)</label>
					<input type="text" className="form-control" id="reimbursed" onChange={this.handleChange} value={this.state.reimbursed} />
				</div>

				<div className="form-group">
					<label className="display-block text-semibold">Repeat Contribution</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" checked="checked" />Off</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Daily</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Weekly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Monthly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Annually</label>
				</div>
              </DialogContent>
              <DialogActions>
                <button id="cancel_btn" className="btn btn-danger pull-right" data-dismiss="modal" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
				        <button className="btn btn-danger pull-left" onClick={this.handleSubmit}><i className="icon-check"></i> Calculate Slices</button>
              </DialogActions>
            </Dialog>

            <Facilitypopup />
          </div>
        );
    }
}


