import React from 'react';
import HomeFooter from './HomeFooter';
import logo from '../assets/img/logo.png';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';
// import '../assets/js/custom.js';

export default class Contributions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          results: [],
          contribs: []
        };
      }


    componentDidMount() {
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }
    
        var handle = this;  
            apiHandler.push(
            "https://api.thepieslicer.com/api/pie/" + userID,
            "GET",
            null,
            function(response) {
                if(response.status === 200) {
                    var data = response.data;
                    var pie_IDs = [];
                    var pId = null;
                    var firstID = data[0].fund_id;

                    for(var i = 0; i < data.length; i++){
                    pie_IDs.push(data[i].fund_id);
                    pId = pie_IDs.join();
                    }

                    handle.contributionData(firstID);

                    handle.getPieInfo(pId);
                    
                }  
                else {
                    swal({
                    title: "Please check the details and try again.",
                    type: "error"
                    }).then(result => {
                    });
                }
            }, headers, true
            );
    }

    getPieInfo = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
    
        var headers = {
          Authorization: "Bearer " + token
          }
    
          var handle = this;  
          apiHandler.push("getpie","POST",{
            id: userID,
            pie_id: id
          },function(response) {
            if(response.status === 200) {
              var data = response.data;
              
              handle.setState({results: data});
    
            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
    
      }

      handlePieChange = (evt) => {
        var id = evt.target.value;
        this.contributionData(id)
      }

      contributionData = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
    
        var headers = {
          Authorization: "Bearer " + token
          }
    
          var handle = this;  
          apiHandler.push("contributions","POST",{
            user_id: userID,
            pie_id: id
          },function(response) {
            if(response.status === 200) {
                var data = response.data;
                var contriType = [
                    " ", 
                    "Time",
                    "Expenses",
                    "Supplies",
                    "Equipment",
                    "Sales",
                    "Royalty", 
                    "Facilities",
                    "Other Cash",
                    "Other Non Cash",
                    "Finder", 
                    "Draw Funds", 
                    "Car Cash", 
                    "Car Non Cash", 
                    "Car Split", 
                    "Finder Non Well", 
                    "Supplies Pre-Owned", 
                    "Equipment Pre-Owned", 
                    "Time Overtime"
                ];

                    for(var x = 0; x < data.data.length; x++) {
                        data.data[x].type = contriType[data.data[x].type];

                        var maxDate = new Date(data.data[x].added);
                        var isoDate = maxDate.toISOString(); // 2014-08-05T19:42:51.429Z
                        data.data[x].added = isoDate.substr(0, 10);
                    }

              handle.setState({contribs: data.data});
    
            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
      }
    

        render() {
        return(
            <div className="layout-boxed navbar-top">
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{marginRight: "7px"}}><img src={logo} style={{width: "58px", height: "58px"}} className="img-rounded" alt="" /></span>
                                {/* <span className="text-semibold header-pie-name">MosquitOasis <i className="icon-arrow-down5 change-pie"></i></span> */}
                                    <select
                                    onChange={this.handlePieChange}
                                    name="name"
                                    id="select_team"
                                    >
                                        {this.state.results.map(function(item, i){
                                            try {
                                                return <option  value={item.funds['id']}>{item.funds["name"]}</option>
                                            } 
                                            catch (error) {
                                            }
                                        })}
                                    </select>
                            </h4>
                        </div>
                    </div>
	            </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel panel-flat">
                                        {/* <div>Aaaaa</div> */}
                                                <table className="table_custom_contribution">
                                                    <thead>
                                                        <tr>
                                                            <th>Team Member</th>
                                                            <th>Contribution</th>
                                                            <th>Project</th>
                                                            <th>Value</th>
                                                            <th>Hour Spent</th>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Slices</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    
                                                    {this.state.contribs.map(function(data, i){
                                                            return  <tr>
                                                                        <td style={{textAlign:"left"}}>
                                                                        {/* style={{color: "#e46c0a" , borderColor: "#e46c0a"}} */}
                                                                            <span className="grunt-name" >{data['name']}</span>
                                                                        </td>
                                                                        <td>{data['type']}</td>
                                                                        <td>{data['project']}</td>
                                                                        <td align="right">{data['value']}</td>
                                                                        <td align="right">{data['added']}<br /><small>40h 0m</small></td>
                                                                        <td>{data['added']}</td>
                                                                        <td>{data['description']}</td>
                                                                        <td align="right">{data['slices']}</td>
                                                                        <td>
                                                                            <i className="icon-pencil7 tooltip-actions" data-popup="tooltip" title="Edit" style={{marginLeft: "2px"}}></i>
                                                                            <i className="icon-trash tooltip-actions" style={{marginLeft: "2px"}} data-popup="tooltip" title="Delete" o></i>
                                                                        </td>
                                                                    </tr>
                                                    })}
                                                    </tbody>
                                                </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </div>
        );
    }

}