import React, { Component } from 'react';
import AddContribution from '../AddContribution';
import Settings from '../Settings';
import TeamSettings from '../TeamSettings';
import ApiHandler from '../../utils/apiHandler';
import swal from 'sweetalert2';

let url = "https://api.thepieslicer.com";

class ActiveTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pie_id: null,
            results: []
        }

    }
    
    componentDidMount() {
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();

        var headers = {
            Authorization: "Bearer " + token
        }

        var handle = this;  
        apiHandler.push("analyticsTeam","POST",{
        id: this.props.updateID
        },function(response) {
            if(response.status === 200) {
                var data = response.data;
                handle.setState({results: data});
            }

            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
        }, headers
        );
    }
    
    
    componentDidUpdate(prevProps) {
        if(prevProps.updateID !== this.props.updateID) {
            this.updateTeamMember(this.props.updateID);
        }
    }

    updateTeamMember = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();

        var headers = {
            Authorization: "Bearer " + token
        }

        var handle = this;  
        apiHandler.push("analyticsTeam","POST",{
        id: id
        },function(response) {
            if(response.status === 200) {
                var data = response.data;
                handle.setState({results: data});
                // console.log(data);
            }

            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
        }, headers
        );
    }
    

    addContribution = () => {
        document.getElementById('add-contribution').click()
    }
  
    settings = () => {
        document.getElementById('team-settings').click();
    }
    
    render() {
        
        return <div>
      { this.state.results.map((item, value) => {
          return (
              <div className="tab-pane active" id="member-active" style={{marginTop: "2vh"}}>
                  
                  {item.data.map((affiliate, ind) => {
                      
                      return (
                        <div className="col-sm-4 thumbnail no-padding grunt-details" style={{borderTop: "5px solid", borderTopColor: affiliate.affiliations["color"]}} data-color={affiliate.affiliations["color"]}>
                            <div className="thumb">
                                <img alt={affiliate.user["first_name"]} src={url + affiliate.user["Image"]}  style={{width: "150px"}}/>
                                <div className="caption-overflow hide">
                                    <span>
                                        <a className="btn btn-danger btn-icon btn-xs" data-popup="tooltip" title="Add Contribution" data-container="body">
                                            <i className="icon-plus2"></i>
                                        </a>
                                        <a href="#" className="btn btn-danger btn-icon btn-xs" data-popup="tooltip" title="Settings" data-container="body">
                                            <i className="icon-cog"></i>
                                        </a>
                                        <AddContribution />
                                        <TeamSettings />
                                        {/* <Settings /> */}
                                    </span>
                                </div>
                            </div>
                            <div className="caption text-center">
                                <h6 className="text-semibold no-margin">
                                    <span className="grunt-name" style={{color: affiliate.affiliations["color"]}}>{affiliate.user["name"]}</span>
                                    <h6 className="display-block grunt-position">{affiliate.affiliations["jobtitle"]}</h6>
                                    <small className="display-block grunt-last-active">Last active {parseInt(affiliate.affiliations["last_active"] / (60*60*24*1000)) + " day/s ago"}</small>
                                </h6>
                                
                                <div className="content-group-sm content-bargroup" style={{marginTop: "10px"}}>
                                    <span className="title-label">SLICES
                                    <span className="title-value">{affiliate.affiliations["tbv"]} ({affiliate.affiliations["tbv_pct"]}%)</span>
                                    </span>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar" style={{width: affiliate.affiliations["tbv"]+"%", backgroundColor: affiliate.affiliations["color"]}}>
                                            <span className="sr-only">{affiliate.affiliations["tbv"]}% Complete</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-group-sm content-bargroup">
                                    <span className="title-label">WELL
                                    <span className="title-value">{affiliate.affiliations["well"]} ({affiliate.affiliations["well_pct"]}%)</span>
                                    </span>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar" style={{width: affiliate.affiliations["well"]+"%", backgroundColor: affiliate.affiliations["color"]}}>
                                            <span className="sr-only">{affiliate.affiliations["well"]}% Complete</span>
                                        </div>
                                    </div>
                                    <div class="content-group-sm content-bargroup">
                                        <span className="title-label">WELL BALANCE
                                        {/* <span className="title-value">{item.summary.currency}</span> */}
                                        <span className="title-value">USD</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="btn-group btn-group-justified">
                                    <a href="#" onClick={this.addContribution} className="btn-group-grunt btn bg-slate-700 btn-add-contribution" data-popup="tooltip" title="Add Contribtion" data-container="body" style={{backgroundColor: affiliate.affiliations["color"], borderColor: affiliate.affiliations["color"]}}>
                                    <i className="icon-plus2"></i>
                                    </a>
                                    <a href="#" onClick={this.settings} className="btn-group-grunt btn bg-slate-700 btn-add-contribution" data-popup="tooltip" title="Settings" data-container="body" style={{backgroundColor: affiliate.affiliations["color"], borderColor: affiliate.affiliations["color"]}}>
                                    <i className="icon-cog"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                      )
                  })}
              </div>
       
        );
      })} 

      </div>
    }
}

export default ActiveTeam;