import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../../utils/apiHandler';
import "../../assets/css/customize.css";
import {getCurrentDate} from '../../utils/getCurrentDate';
import Timepopup from './Timepopup';


export default class Time extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
          open: false,
          openInfo: false,
          type: 1,
          repeat: 0,
          date: getCurrentDate(),
          project: "",
          time_spent: "",
          reimbursed: "",
          aff: "",
          pie_id: localStorage.getItem('def_id'),
          user_id: localStorage.getItem('userID'),
          description: "",
          name: localStorage.getItem('name')

        };
      }

      componentDidMount() {
        
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
        var pieID = localStorage.getItem('def_id');
          
          if(localStorage.getItem('aff')){
            pieID = localStorage.getItem('aff');
            this.setState( {pie_id: localStorage.getItem('aff')} );
          }


        var headers = {
          Authorization: "Bearer " + token
        }

        var handle = this;  
          apiHandler.push("getpie","POST",{
            id: userID,
            pie_id: pieID
          },function(response) {
            if(response.status === 200) {
              var data = response.data;
              var IDS = [];
                
                for(var a = 0; a < data[0].contributors.length; a++) {
                    IDS.push(data[0].contributors[a].id);
                }

                handle.getAffiliateID(IDS);

            }

            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
      }

      getAffiliateID = (id) => {

        var arrID = id;
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var pNames = [];
        var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }
    
        var handle = this;  
        apiHandler.push(
        "https://api.thepieslicer.com/api/pie/" + userID,
        "GET",
        null,
        function(response) {
        if(response.status === 200) {
            var data = response.data;
            
            for(var x = 0; x < data.length; x++) {
              if ( arrID.includes(data[x].id) ){
                handle.setState( {aff: data[x].id} );
              }
            }
            
        }  
        else {
            swal({
            title: "Please check the details and try again.",
            type: "error"
            }).then(result => {
            });
        }
        }, headers, true
        );
      }
      

    
    handleClickOpen = () => {
        this.setState({open: true});
    }

    infoClick = () => {
        document.getElementById('time-popup').click();
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      });
    };


    handleSubmit = (event) => {
      
      var token = localStorage.getItem('token');
      event.preventDefault();
      let apiHandler = new ApiHandler();

      var headers = {
        Authorization: "Bearer " + token
      }

      apiHandler.push(
      "contribute",
      "POST",
      {
          type: this.state.type,
          date: this.state.date,
          project: this.state.project,
          time_spent: this.state.time_spent,
          reimbursed: this.state.reimbursed,
          aff: this.state.aff,
          pie_id: this.state.pie_id,
          user_id: this.state.user_id,
          description: this.state.description
          
      },
      function(response) {
      console.log(response);
      if(response.status === 200) {
          // valid
              document.getElementById('hide_btn').click();
              localStorage.removeItem('pie_name');
              swal({
              title: "Time Contribution Added Successfully!",
              type: "success"
              }).then(result => {
                window.location.href="/";
              });
      }  
      else {
          swal({
          title: "Invalid Login",
          type: "error"
          }).then(result => {
          });
      }
      }, headers
      );
    }


    render() {
        return(
            <div>
            <Button id="contrib-time" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
                    <button type="button" className="close" onClick={this.infoClick}>
                      <i className="icon-info22 helper-icon" data-popup="popover" data-placement="bottom" title="HELPER"></i>
                    </button>
                    <i className="icon-sort-time-asc"></i> &nbsp;Time
              </DialogTitle>
              <DialogContent>
                <form onSubmit={this.handleSubmit}>
                <div className="alert alert-warning ">
	                Adding contribution for <strong>{this.state.name}</strong>
	            </div>
                <div className="form-group">
					<label>Date <span className="requiredFields">*</span></label>
					<div className="input-group">
						<span className="input-group-addon"><i className="icon-calendar2"></i></span>
						<input type="text" className="form-control pickadate-limits" placeholder="Pick a date" value={getCurrentDate()} id="date"/>
					</div>
				</div>
                <div className="form-group">
					<label>Projects <span className="requiredFields">*</span></label>
					<select className="form-control" id="project" onChange={this.handleChange} required>
						<option value="">Choose an Option</option>
						<option value={"Sales"}>Sales</option>
						<option value={"Marketing"}>Marketing</option>
						<option value={"Product Development"}>Product Development</option>
						<option value={"Finance"}>Finance</option>
						<option value={"Administration"}>Administration</option>
						<option value={"General Operations"}>General Operations</option>
						<option value={"Research"}>Research</option>
					</select>
				</div>
        <div className="form-group">
					<label>Description</label>
					<textarea className="form-control" id="description" onChange={this.handleChange} value={this.state.description}></textarea>
				</div>

				<div className="form-group">
					<label>Hours Spent <span className="requiredFields">*</span></label>
					<input type="text" className="form-control" id="time_spent" onChange={this.handleChange} value={this.state.time_spent} required/>
					<span className="help-block">Valid time <code>HH:MM</code> or <code>decimal</code></span>
				</div>

				<div className="form-group">
					<label>Cash Payment Received (if any)</label>
					<input type="text" className="form-control" id="reimbursed" onChange={this.handleChange} value={this.state.reimbursed} />
				</div>

				<div className="form-group">
					<label className="display-block text-semibold">Repeat Contribution</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" checked="checked" />Off</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Daily</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Weekly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Monthly</label>
					<label className="radio-inline"><input type="radio" name="radio-inline-left" className="styled" />Annually</label>
				</div>
        <button className="btn btn-danger pull-right" data-dismiss="modal" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
        <button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Calculate Slices</button>
        </form>
              </DialogContent>
              <DialogActions>
                  <button id="hide_btn" type="button" style={{display: "none"}} onClick={this.handleClose}>Close</button>
              </DialogActions>
            </Dialog>

            <Timepopup />
          </div>
        );
    }
}


