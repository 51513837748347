import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';
import user from '../../assets/img/user_1.jpg';
import "../../assets/css/customize.css";

class FindGrunts extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{marginTop: "7px"}}>
                                    <img src={logo} style={{width: "58px" , height: "58px"}} className="img-rounded" />
                                </span>
                                <span className="text-semibold header-pie-name">
                                    MosquitOasis
                                    <i className="icon-arrow-down5 change-pie"></i>
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="panel panel-flat">
                                        <div className="panel-heading">
                                            <h5 className="panel-title">
                                                Filter Criteria
                                            </h5>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="job_title" className="form-control" placeholder="Job Title or Headline..." />
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-danger" style={{width: "100%", marginBottom: "10px"}}>Search</button>
                                                        <button type="button" className="btn btn-danger" style={{width: "100%"}}>Clear Filters</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="panel panel-flat" style={{marginBottom: "10px !important"}}>
                                        <div className="panel-body">
                                            <dl style={{border: "none", marginBottom: 0, padding: 0}}>
                                                <dt className="col-md-2">
                                                    <div style={{backgroundImage: "url("+ user +")"}} id="grunt_img"></div>
                                                </dt>
                                                <dd className="col-md-10">
                                                    <a>
                                                        <h4>Web Developer/Designer</h4>
                                                        <span>She is an aspiring online freelancer who aims to help your business establish a social media presence. 
                                                            With the goal of earning at home while spending time with my family borne out the desire to try freelancing...</span>  
                                                    </a>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="panel panel-flat" style={{marginBottom: "10px !important"}}>
                                        <div className="panel-body">
                                            <dl style={{border: "none", marginBottom: 0, padding: 0}}>
                                                <dt className="col-md-2">
                                                    <div style={{backgroundImage: "url("+ user +")"}} id="grunt_img"></div>
                                                </dt>
                                                <dd className="col-md-10">
                                                    <a>
                                                        <h4>Web Developer/Designer</h4>
                                                        <span>She is an aspiring online freelancer who aims to help your business establish a social media presence. 
                                                            With the goal of earning at home while spending time with my family borne out the desire to try freelancing...</span>  
                                                    </a>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindGrunts;