import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../../assets/css/customize.css";
import {getCurrentDate} from '../../utils/getCurrentDate';

export default class DrawFunds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          openInfo: false,
          date: getCurrentDate(),
        };
      }

    
    handleClickOpen = () => {
        this.setState({open: true});
    }

    infoClick = () => {
        document.getElementById('facility-popup').click();
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      });
    };


    render() {
        return(
            <div>
            <Button id="well-draw-fund" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
              <button type="button" className="close" onClick={this.infoClick}>
                  <i className="icon-info22 helper-icon" data-popup="popover" data-placement="bottom" title="HELPER" data-html="true"></i>
              </button>
                <h5 class="modal-title"><i class="icon-minus-circle2"></i> &nbsp;Draw Funds from Well</h5>
              </DialogTitle>
              <DialogContent>
                <div class="form-group">
					<label>Amount Invested</label>
					<input type="text" class="form-control" />
				</div>

				<div class="form-group">
					<label>Description</label>
					<textarea class="form-control"></textarea>
				</div>
              </DialogContent>
              <DialogActions>
                <button class="btn btn-danger pull-right" onClick={this.handleClose}><i class="icon-cross"></i> Cancel</button>
				<button class="btn btn-danger pull-left"><i class="icon-check"></i> Draw Funds</button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}


