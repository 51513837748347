import React from 'react';
import logo from '../assets/img/logo.png';
// import userJayson from '../assets/img/jayson.png';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Link } from "react-router-dom";
import '../assets/css/customize.css';


let url = "https://api.thepieslicer.com";
export default class NavbarHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: localStorage.getItem('name')
        }
    }

    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        window.location.href = '/';
    }


    render() {
        return(
            <div className="navbar navbar-default navbar-fixed-top" id="navbar-main">
	            <div className="navbar-boxed">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#"><img src={logo} alt="" /></a>

                        <ul className="nav navbar-nav pull-right visible-xs-block">
                            <li><a data-toggle="collapse" data-target="#navbar-mobile"><i className="icon-tree5"></i></a></li>
                        </ul>
                    </div>
                    
                    <div className="navbar-collapse collapse" id="navbar-mobile">
                        <ul className="nav navbar-nav navbar-right" style={{display: "block"}}>
                        <li className='active'> <Link to="/"> <i className="icon-home4 position-left"></i> Home </Link> </li>
                            <li>
                                <Navbar bg="light" expand="lg">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse className="basic-navbar-nav">
                                        <Nav>
                                        <i className="icon-clipboard position-left"></i>
                                        <NavDropdown title="Bakery" id="basic-nav-dropdown">
                                            <NavDropdown.Item > <Link to="/#"> <i className="icon-plus22"></i> Post Gruntwork </Link> </NavDropdown.Item>
                                            <NavDropdown.Item > <Link to="/gruntworks"> <i className="icon-clipboard3"></i> My Gruntworks </Link> </NavDropdown.Item>
                                            <NavDropdown.Item > <Link to="/find_gruntwork"> <i className="icon-clipboard3"></i> Find Gruntwork </Link> </NavDropdown.Item>
                                            <NavDropdown.Item > <Link to="/find_grunts"> <i className="icon-users"></i> Find Grunts </Link> </NavDropdown.Item>
                                        </NavDropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </li>
                            
                            <li>
                            <Navbar bg="light" expand="lg">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="basic-navbar-nav">
                                    <Nav>
                                    <i className="icon-file-stats position-left"></i>
                                    <NavDropdown title="Reports" id="basic-nav-dropdown">
                                        <NavDropdown.Item > <Link to="/analytics"> <i className="icon-stats-bars2"></i> Analytics </Link> </NavDropdown.Item>
                                        <NavDropdown.Item > <Link to="/contributions"> <i className="icon-puzzle2"></i> Contributions </Link> </NavDropdown.Item>
                                        <NavDropdown.Item > <Link to="/lumpsum"> <i className="icon-coins"></i> Lump-Sum Payments </Link> </NavDropdown.Item>
                                        <NavDropdown.Item > <Link to="/summary"> <i className="icon-file-check"></i> Summary </Link> </NavDropdown.Item>
                                        <NavDropdown.Item > <Link to="/well"> <i className="icon-file-text"></i> Well Log </Link> </NavDropdown.Item>
                                    </NavDropdown>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            </li>
                            
                            <li className="dropdown">
                                <Navbar bg="light" expand="lg">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse className="basic-navbar-nav">
                                        <Nav>
                                        <i className="icon-question4 position-left"></i>
                                        <NavDropdown title="Help" id="basic-nav-dropdown">
                                            <NavDropdown.Item > <Link to="/user-guide"> <i className="icon-book"></i> User Guide </Link> </NavDropdown.Item>
                                            <NavDropdown.Item > <Link to="/contact-support"> <i className="icon-comment-discussion"></i> Contact Support </Link> </NavDropdown.Item>
                                        </NavDropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </li>

                            <li className="dropdown dropdown-user">
                                <Navbar bg="light" expand="lg">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse className="basic-navbar-nav">
                                        <Nav>
                                        <img src={url + localStorage.getItem('imgSrc')} alt="" />
                                        <NavDropdown title={this.state.name} id="basic-nav-dropdown">
                                            <NavDropdown.Item > <Link to="/account"> <i className="icon-cog5"></i> Account Settings </Link> </NavDropdown.Item>
                                            <NavDropdown.Item onClick={this.handleLogout}> <Link to="/"> <i className="icon-switch2"></i> Logout </Link> </NavDropdown.Item>
                                        </NavDropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </li>
                        </ul>
                    </div>
	            </div>
            </div>
        );
    }
}