import React from 'react';
import Footer from './Footer';
import '../assets/css/customize.css';
import logo from '../assets/img/logo-centered.png';
import { BrowserRouter as Router, Link } from "react-router-dom";


export default class ForgotPassword extends React.Component {

    reloadPage = () => {
        window.location.href="/";
    }

    componentDidMount() {
        document.title = "Pie Name - Pie Slicer";
    }

    render() {
        return(
            <div className="login-container">
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <center>
                                <img src={logo} alt="logo-img" />
                            </center>
                                <form id="forgot-pass-form">
                                    <div className="panel panel-body login-form">
                                        <div className="text-center">
                                            <h5 className="content-group">Forgot Password <small className="display-block">Enter your email below to receive a password reset link</small></h5>
                                        </div>

                                        <div className="form-group has-feedback has-feedback-left">
                                            <input type="text" className="form-control" placeholder="Email Address" />
                                            <div className="form-control-feedback">
                                                <i className="icon-user text-muted"></i>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-danger btn-block">Submit</button>
                                        </div>

                                        <Router>
                                            <div className="text-center">
                                                <Link to="/" onClick={this.reloadPage}>Back to login</Link>
                                            </div>
                                        </Router>
                                        
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
            <Footer />    
            </div>
            
        );
    }
}
