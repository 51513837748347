import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';
import '../assets/css/customize.css';

class DeletePie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pie_name: this.props.pieName
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.pieName !== this.props.pieName) {
            console.log(this.props.pieName);
            this.setState( {pie_name: this.props.pieName} );
        }
    }
    

    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () => {
        this.setState({open: false});
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
        console.log(this.state.pie_name);
    }

    handleRadio = (evt) => {
        var value = evt.target.value;
        var div = document.getElementById('delete_pie_other');
            if(value === "Other") {
                div.style.display = "block";
            }
            else{
                div.style.display = "none";
            }
    }
    
    render() {
        return (
            <div id="delete-pie-modal">
                <Button id="delete-pie" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
                    Open form dialog
                </Button>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="delete-pie-dialog">
                    <DialogTitle >
                        <h5 className="modal-title"><i className="icon-info22"></i> &nbsp;Delete Pie: {this.state.pie_name} Account</h5>
                    </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <div className="modal-body">
                            <p align="justified">
                                Deleting your Pie will cancel ongoing charges and delete the Pie's contents. If you are participating in other Pies on this account you will still be able to access them.
                                If you own other Pies you will continue to own them and charges will continue until they, too, are deleted.
                                </p>
                            <p class="text-semibold">Before deleting your Pie, please take a moment to let us know why!</p>
                            <div className="radio" style={{marginBottom: "8px !important"}}>
                                <label>
                                    <input type="radio" name="delete_pie_option" className="delete-pie-option styled" value={"We reached breakeven and no longer need it"} onChange={this.handleRadio} />
                                    We reached breakeven and no longer need it
                                </label>
                            </div>
                            <div className="radio" style={{marginBottom: "8px !important"}}>
                                <label>
                                    <input type="radio" name="delete_pie_option" className="delete-pie-option styled" value={"We raised money and no longer need it"} onChange={this.handleRadio} />
                                    We raised money and no longer need it
                                </label>
                            </div>
                            <div className="radio" style={{marginBottom: "8px !important"}}>
                                <label>
                                    <input type="radio" name="delete_pie_option" className="delete-pie-option styled" value={"We closed the company or ended the project"} onChange={this.handleRadio} />
                                    We closed the company or ended the project
                                </label>
                            </div>
                            <div className="radio" style={{marginBottom: "8px !important"}}>
                                <label>
                                    <input type="radio" name="delete_pie_option" className="delete-pie-option styled" value={"I was just testing the program and have no current need"} onChange={this.handleRadio} />
                                    I was just testing the program and have no current need
                                </label>
                            </div>
                            <div className="radio" style={{marginBottom: "8px !important"}}>
                                <label>
                                    <input type="radio" name="delete_pie_option" className="delete-pie-option styled" value={"Other"} onChange={this.handleRadio} />
                                    Other reason/feedback
                                </label>
                            </div>
                            <div className="form-group" id="delete_pie_other" style={{display: "none"}}>
                                <textarea className="form-control" rows={3} placeholder="Please specify your reason or feedback..."></textarea>
                            </div>
                            <div className="form-group" style={{marginTop: "20px"}}>
                                <label className="text-semibold">Enter password</label>
                                <input type="password" className="form-control" name="reset_pie_password" required placeholder="Enter Password" />
                            </div>
                        </div>

                        <div id="delete-pie-btns">
                            <button className="btn btn-danger pull-right" onClick={this.handleClose} ><i className="icon-cross"></i> Cancel</button>
                            <button className="btn btn-danger pull-left"><i className="icon-check"></i> Delete Pie</button>
                        </div>
                        
                    </form>
                </DialogContent>
                    {/* <DialogActions>
                    <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                    <button type="submit" className="btn btn-danger pull-left"><i className="icon-check"></i> Yes, I want to reset this Pie</button>
                    </DialogActions> */}
                </Dialog>
            </div>
        );
    }
}

export default DeletePie;