import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApiHandler from '../../utils/apiHandler';
import swal from 'sweetalert2';

class PieSlices extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            legendData: [],
            totalSlices: null,
            series: [19510, 1000, 2200],
            options: {
                chart: {
                width: 380,
                type: 'pie',
                },
            labels: ['Sales', 'General Operations', 'Marketing'],
            colors:['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
            }]
            },
        };
    }

    componentDidMount() {
        var token = localStorage.getItem('token');
        var apiHandler = new ApiHandler();

        var headers = {
            Authorization: "Bearer " + token
        }

        var handle = this;  
        apiHandler.push("analyticsTeam","POST",{
            id: this.props.updateID,
          },function(response) {
            if(response.status === 200) {
              var data = response.data;
              console.log(data);
              handle.setState({legendData: data});
              var seriesData = [];
              var labelData = [];
              var totalSlices = 0;
            
            for(var i = 0; i < data.length; i++) {
                for(var x = 0; x < data[i].data.length; x++){
                    if(data[i].data[x].slices === null){
                        data[i].data[x].slices = 0;
                    }

                    if(data[i].data[x].user.name === null){
                    data[i].data[x].user.name = 'Unknown';
                    }
                    seriesData.push(parseInt(data[i].data[x].slices));
                    labelData.push(data[i].data[x].user.name);
                    totalSlices += parseInt(data[i].data[x].slices);
                }
            }
            
            handle.setState({
                series: seriesData,
                options: {...handle.state.options,
                    labels: labelData
                }
              })
            
            handle.setState({totalSlices: totalSlices});
            
            
            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
    }
    

    componentDidUpdate(prevProps) {
        if(prevProps.updateID !== this.props.updateID) {
            this.updateGraph(this.props.updateID);
        }
    }

    updateGraph = (id) => {
        var token = localStorage.getItem('token');
        var apiHandler = new ApiHandler();

        var headers = {
            Authorization: "Bearer " + token
        }

        var handle = this;  
        apiHandler.push("analyticsTeam","POST",{
            id: id,
          },function(response) {
            if(response.status === 200) {
              var data = response.data;
              handle.setState({legendData: data});
              var seriesData = [];
              var labelData = [];
              var totalSlices = 0;
            
              for(var i = 0; i < data.length; i++) {
                for(var x = 0; x < data[i].data.length; x++){
                    if(data[i].data[x].slices === null){
                        data[i].data[x].slices = 0;
                    }

                    if(data[i].data[x].user.name === null){
                    data[i].data[x].user.name = 'Unknown';
                    }
                    seriesData.push(parseInt(data[i].data[x].slices));
                    labelData.push(data[i].data[x].user.name);
                    totalSlices += parseInt(data[i].data[x].slices);
                }
            }
  
                    handle.setState({
                      series: seriesData,
                      options: {...handle.state.options,
                          labels: labelData
                      }
                    });
                    
                    handle.setState({totalSlices: totalSlices});
           
            }
    
            else {
                swal({
                title: "Please check your information!",
                type: "error"
                }).then(result => {
                });
            }
          }, headers
          );
    }
    
    render() {
        return <div className="tab-content">
                <div className="tab-pane active" id="pie-slices">
                    <div className="panel-heading">
                    <h6 className="panel-title">PIE SLICES <span className="pie-slices-count">{this.state.totalSlices}</span>
                    </h6>
                    </div>
              <div className="panel-body">
                  <div className="chart-container text-center">
                      <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width="400" />
                  </div>
                  <div className="pie-legend">
                      {/* { this.state.legendData.map((item, num) => {
                        return (
                          <div className="grunt-legend" style={{borderColor: item.color}} data-popup="tooltip" title="Mong: 85.9%" data-container="body">
                              <div className="grunt-legend-text">
                                  <span className="name">{item.data[0].name}</span>
                                  <span className="value clearfix" title="100 Slices">{item.data[0].slices}</span>
                              </div>
                          </div>
                        );
                      })}  */}
                  </div>
              </div>
      </div>
      
  </div>
    }
}

export default PieSlices;