import React from 'react';
import Signup from './Signup';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import { BrowserRouter } from 'react-router-dom';
import { Route } from "react-router";


export default class WelcomeRoute extends React.Component {

    render() {
        return(
            <div className="layout-boxed navbar-top">
        <BrowserRouter>
			<Route exact path="/" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/forgot-password" component={ForgotPassword} />
        </BrowserRouter>
            </div>
        );
    }
}
