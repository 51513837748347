import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../../utils/apiHandler';
import "../../assets/css/customize.css";
import {getCurrentDate} from '../../utils/getCurrentDate';

export default class AddFunds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          openInfo: false,
          date: getCurrentDate(),
          user_id: localStorage.getItem('userID'),
          pie_id: 3300,
          description: null,
          amount: null,
          investor: null
        };
      }

    
    handleClickOpen = () => {
        this.setState({open: true});
    }

    infoClick = () => {
        document.getElementById('facility-popup').click();
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      });
    };

    handleAddFund = event => {
    var token = localStorage.getItem('token');
    event.preventDefault();
    let apiHandler = new ApiHandler();

    var headers = {
    Authorization: "Bearer " + token
    }

    apiHandler.push(
    "invest",
    "POST",
    {
        
        pie_id: this.state.pie_id,
        user_id: this.state.user_id,
        description: this.state.description,
        amount: this.state.amount,
        investor: this.state.investor
       
    },
    function(response) {
    console.log(response);
    if(response.status === 200) {
        // valid
            swal({
            title: "Funds added Successfully!",
            type: "success"
            }).then(result => {
            window.location.reload();
            });
    }  
    else {
        swal({
        title: "Please check the details and try again.",
        type: "error"
        }).then(result => {
        });
    }
    }, headers
    );
    }


    render() {
        return(
            <div>
            <Button id="well-add-fund" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
            Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
              <button type="button" className="close" onClick={this.infoClick}>
                  <i className="icon-info22 helper-icon" data-popup="popover" data-placement="bottom" title="HELPER" data-html="true"></i>
              </button>
                <h5 class="modal-title"><i class="icon-plus-circle2"></i> &nbsp;Add Funds to Well</h5>
              </DialogTitle>
              <DialogContent>
                <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" id="description" onChange={this.handleChange}></textarea>
                    </div>

                    <div class="form-group">
                        <label>Amount Invested <span class="requiredFields">*</span></label>
                        <input type="text" class="form-control" id="amount" onChange={this.handleChange} />
                    </div>

                    <div class="form-group">
                        <label>Investor <span class="requiredFields">*</span></label>
                        <select class="form-control" id="investor" onChange={this.handleChange}>
                            <option>Choose a Team Member</option>
                            <option value={"Mike Moyer"}>Mike Moyer</option>
                            <option value={"Jayson Sarino"}>Jayson Sarino</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Finder's Fee Recipient (optional) </label> <i class="icon-question3 helper-icon" data-popup="tooltip" title="Who found this investor" data-placement="right"></i>
                        <select class="form-control">
                            <option></option>
                            <option value="">Mike Moyer</option>
                            <option value="">Jayson Sarino</option>
                        </select>
                    </div>
              </DialogContent>
              <DialogActions>
                <button class="btn btn-danger pull-right" onClick={this.handleClose}><i class="icon-cross"></i> Cancel</button>
				        <button class="btn btn-danger pull-left" onClick={this.handleAddFund}><i class="icon-check"></i> Add Funds</button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}


