import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../assets/css/customize.css';
import {getCurrentDate} from '../utils/getCurrentDate';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
    
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSaveSettings = (event) => {
    var overtime_eligibility = 5;
    var aff_id = 11162;
    var pie_id = localStorage.getItem('aff');
    var user_id = localStorage.getItem('userID');
    var name = localStorage.getItem('name');
    var email = localStorage.getItem('email');
    var jobtitle = document.getElementById('jobtitle').value;
    var salary = document.getElementById('settings_salary').value;
    var account_type = document.getElementById('settings_account_type').value;
    var radios = document.getElementsByName('restriction');
    for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
            var restriction = radios[i].value;
    }
}

    var token = localStorage.getItem('token');
    event.preventDefault();
    let apiHandler = new ApiHandler();

    var headers = {
    Authorization: "Bearer " + token
    }

    apiHandler.push(
    "team",
    "PUT",
    {
        
        jobtitle: jobtitle,
        salary: salary,
        account_type: account_type, 
        restriction: restriction,
        overtime_eligibility: overtime_eligibility,
        aff_id: aff_id,
        pie_id: pie_id,
        user_id: user_id,
        name: name,
        email: email

    },
    function(response) {
    if(response.status === 200) {
        // valid
            document.getElementById('btn_hide').click();
            swal({
            title: "Pie Settings Updated Successfully!",
            type: "success"
            }).then(result => {
            window.location.href="/";
            });
    }  
    else {
        swal({
        title: "Please check the details and try again.",
        type: "error"
        }).then(result => {
        });
    }
    }, headers
    );

  }

  const handleLumpsum = (event) => {
    var aff_id = 11162;
    var pie_id = localStorage.getItem('aff');
    var user_id = localStorage.getItem('userID');
    var lumpamount = document.getElementById('lumpsum_payment').value;
    var lumpdescription = document.getElementById('lumpsum_description').value;

    var token = localStorage.getItem('token');
    event.preventDefault();
    let apiHandler = new ApiHandler();

    var headers = {
    Authorization: "Bearer " + token
    }

    apiHandler.push(
    "lumpsum",
    "POST",
    {
        
        aff_id: aff_id,
        pie_id: pie_id,
        user_id: user_id,
        lumpamount: lumpamount,
        lumpdescription: lumpdescription

    },
    function(response) {
    console.log(response);
    if(response.status === 200) {
        // valid
            document.getElementById('hide_button').click();
            swal({
            title: "Pie Settings Updated Successfully!",
            type: "success"
            }).then(result => {
            window.location.reload();
            });
    }  
    else {
        swal({
        title: "Please check the details and try again.",
        type: "error"
        }).then(result => {
        });
    }
    }, headers
    );

  }
  


  return (
    <div className={classes.root}>
      <Button id="settings-contribution" variant="outlined" color="primary" onClick={handleClickOpen} style={{display: "none"}}>
        Open form dialog
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
         <h5 class="modal-title"><i class="icon-cog"></i> &nbsp;Settings: {localStorage.getItem('name')} ({localStorage.getItem('email')})</h5>
      </DialogTitle>
      <DialogContent>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" id="settings-tab">
            <Tab label="Settings" {...a11yProps(0)} />
            <Tab label="Lump-Sum Payment" {...a11yProps(1)} />
            <Tab label="Remove/Terminate" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
          <TabPanel value={value} index={0}>
          <div className="tab-pane active" id="grunt-settings-tab">
            <form onSubmit={handleSaveSettings}>
							<div className="form-group">
								<label>Job Title<span className="requiredFields">*</span></label>
								<input type="text" className="form-control" name="settings_job_title" id="jobtitle" required/>
							</div>
							<div className="form-group">
								<label>Fair Market Salary<span className="requiredFields">*</span></label>
								<input type="text" className="form-control" name="settings_salary" id="settings_salary" required />
							</div>
							<div className="form-group">
								<label>Account Type <span className="requiredFields">*</span></label>
                <select id="cars" className="form-control" id="settings_account_type" required>
                  <option value="">Choose Account Type</option>
									<option value={1}>Owner</option>
									<option value={2}>Executive</option>
									<option value={3}>Adviser</option>
									<option value={4}>Employee</option>
                </select>
							</div>
							<div className="form-group">
								<label className="display-block text-semibold">Restriction</label>
								<label className="radio-inline">
                  <span className="checked">
									<input type="radio" name="restriction" className="styled" value={0} />
                  </span>
                  None
								</label>
								<label className="radio-inline">
                  <span className="">
									<input type="radio" name="restriction" className="styled" value={1} />
                  </span>
									Contributions Require Approval
								</label>
								<label className="radio-inline">
                  <span className="">
									<input type="radio" name="restriction" className="styled" value={2} />
                  </span>
									View Only
								</label>
							</div>
							<div className="form-group row" style={{marginTop: "45px", display: "block"}}>
								<button className="btn btn-danger pull-right" onClick={handleClose}><i className="icon-cross"></i> Cancel</button>
								<button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Save Changes</button>
                <input type="hidden" id="btn_hide" onClick={handleClose} />
							</div>
              </form>
						</div>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <div className="tab-pane" id="grunt-make-lumpsum-tab">
            <form onSubmit={handleLumpsum}>
							<p>
								When you make a lump-sum payment, the Pie Slicer will reduce the number of slices this person contributed starting with cash contributions, then their Well balance, then non-cash contributions.
							</p>
							<div className="form-group">
								<label>Date<span className="requiredFields">*</span></label>
								<div className="input-group">
									<span className="input-group-addon"><i className="icon-calendar2"></i></span>
									<input type="text" className="form-control pickadate-limits" placeholder="Pick a date" value={getCurrentDate()} readOnly />
								</div>
							</div>
							<div className="form-group">
								<label>Description</label>
								<textarea className="form-control" id="lumpsum_description"></textarea>
							</div>
							<div className="form-group">
								<label>Lump-Sum Payment Amount<span className="requiredFields">*</span></label>
								<input type="number" className="form-control" required id="lumpsum_payment" />
							</div>
							<div className="form-group row" style={{marginTop: "45px", display: "block"}}>
								<button className="btn btn-danger pull-right" onClick={handleClose}><i className="icon-cross"></i> Cancel</button>
								<button type="submit" className="btn btn-danger pull-left" ><i className="icon-check"></i> Reduces Slices</button>
                <input type="hidden" id="hide_button" onClick={handleClose} />
							</div>
              </form>
						</div>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <div className="tab-pane" id="grunt-terminate-tab">
          <div className="form-group row" style={{display: "block"}}>
								<div className="radio" style={{marginTop: "10px !important" , marginLeft: "10px !important" , fontSize: "13px" , padding: "5px"}}>
									<label>
                    <input type="radio" name="stacked-radio-left" className="styled" />
                    Terminate for Good Reason
                  </label> 
                    <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
								</div>
								<div className="radio" style={{marginTop: "10px !important" , marginLeft: "10px !important" , fontSize: "13px" , padding: "5px"}}>
									<label>
                    <input type="radio" name="stacked-radio-left" className="styled" />
                    Terminate for No Good Reason
                  </label> 
                  <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
								</div>
								<div className="radio" style={{marginTop: "10px !important" , marginLeft: "10px !important" , fontSize: "13px" , padding: "5px"}}>
									<label>
                    <input type="radio" name="stacked-radio-left" className="styled" />
                    Resign for Good Reason
                    </label> 
                    <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
								</div>
								<div className="radio" style={{marginTop: "10px !important" , marginLeft: "10px !important" , fontSize: "13px" , padding: "5px"}}>
									<label>
                    <input type="radio" name="stacked-radio-left" className="styled" />
                    Resign for No Good Reason
                  </label> 
                  <i className="icon-info22 helper-icon" data-popup="popover" title="HELPER" data-html="true" data-content="Helper text here"></i>
								</div>
							</div>
							<div className="form-group row" style={{marginTop: "45px", display: "block"}}>
								<button className="btn btn-danger pull-right" onClick={handleClose}><i className="icon-cross"></i> Cancel</button>
								<button className="btn btn-danger pull-left"><i className="icon-check"></i> Remove Team Member</button>
							</div>
          </div>
          </TabPanel>
        <DialogActions>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}



            
            
              
              

              