import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';

class Gruntworks extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{marginTop: "7px"}}>
                                    <img src={logo} style={{width: "58px" , height: "58px"}} className="img-rounded" />
                                </span>
                                <span className="text-semibold header-pie-name">
                                    MosquitOasis
                                    <i className="icon-arrow-down5 change-pie"></i>
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gruntworks;