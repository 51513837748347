import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../assets/css/customize.css";
import ContributionTime from './contributions/Time';
import ContributionExpenses from './contributions/Expenses';
import ContributionSupplies from './contributions/Supplies';
import ContributionSales from './contributions/Sales';
import ContributionRoyalty from './contributions/Royalty';
import ContributionFacilities from './contributions/Facilities';
import ContributionFinders from './contributions/Finders';
import ContributionOthers from './contributions/Others';


export default class AddContribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
        };
      }

    
    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () => {
        this.setState({open: false});
    }

    contributionTime = () => {
        document.getElementById('contrib-time').click();
        // document.getElementById('hide_btn').click();
    }

    contributionExpenses = () => {
        document.getElementById('contrib-expenses').click();
        // document.getElementById('hide_btn').click();
    }

    contributionSupplies = () => {
      document.getElementById('contrib-supplies').click();
      // document.getElementById('hide_btn').click();
    }
    
    contributionSales = () => {
      document.getElementById('contrib-sales').click();
      // document.getElementById('hide_btn').click();
    }

    contributionRoyalty = () => {
      document.getElementById('contrib-royalty').click();
      // document.getElementById('hide_btn').click();
    }

    contributionFacilities = () => {
      document.getElementById('contrib-facilities').click();
      // document.getElementById('hide_btn').click();
    }

    contributionFinders = () => {
      document.getElementById('contrib-finders').click();
      // document.getElementById('hide_btn').click();
    }

    contributionOthers = () => {
      document.getElementById('contrib-others').click();
      // document.getElementById('hide_btn').click();
    }

    render() {
        return(
            <div>
            <Button id="add-contribution" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
              Open form dialog
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="contribution">
              <DialogTitle id="form-dialog-title"><i className="icon-plus2"></i> &nbsp;Select Contribution</DialogTitle>
              <DialogContent>
              <div className="list-group">
                <a className="list-group-item pick-contribution" data-contribution="time" onClick={this.contributionTime}>
                  <h6 className="list-group-item-heading"><i className="icon-sort-time-asc position-left"></i> TIME</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="expenses" onClick={this.contributionExpenses}>
                  <h6 className="list-group-item-heading"><i className="icon-price-tag position-left"></i> EXPENSES</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="supplies" onClick={this.contributionSupplies}>
                  <h6 className="list-group-item-heading"><i className="icon-package position-left"></i> SUPPLIES</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="sales" onClick={this.contributionSales}>
                  <h6 className="list-group-item-heading"><i className="icon-stats-dots position-left"></i> SALES</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="royalty" onClick={this.contributionRoyalty}>
                  <h6 className="list-group-item-heading"><i className="icon-crown position-left"></i> ROYALTY</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="facilities" onClick={this.contributionFacilities}>
                  <h6 className="list-group-item-heading"><i className="icon-office position-left"></i> FACILITIES</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="finders" onClick={this.contributionFinders}>
                  <h6 className="list-group-item-heading"><i className="icon-coin-dollar position-left"></i> FINDER'S FEE</h6>
                </a>

                <div className="list-group-divider"></div>

                <a className="list-group-item pick-contribution" data-contribution="other" onClick={this.contributionOthers}>
                  <h6 className="list-group-item-heading"><i className="icon-more position-left"></i> OTHER</h6>
                </a>
                <ContributionTime />
                <ContributionExpenses />
                <ContributionSupplies />
                <ContributionSales />
                <ContributionRoyalty />
                <ContributionFacilities />
                <ContributionFinders />
                <ContributionOthers />
				    </div>
              </DialogContent>
              <DialogActions>
                <button id="hide_btn" className="btn btn-danger pull-right" onClick={this.handleClose} style={{display: "none"}}><i className="icon-cross"></i> 
                  Cancel
                </button>
                {/* <Button onClick={this.handleClose} color="primary">
                  Subscribe
                </Button> */}
              </DialogActions>
            </Dialog>
            
          </div>
        );
    }
}