import React from 'react';
import HomeFooter from './HomeFooter';
import logo from '../assets/img/logo.png';


export default class Lumpsum extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // this.state = {
    //     //   open: false,
    //     // };
    // }

    componentDidMount() {
        
    }
    
    render() {
        return (
            <div className="layout-boxed navbar-top">
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{ marginRight: "7px" }}><img src={logo} style={{ width: "58px", height: "58px" }} className="img-rounded" alt="" /></span>
                                <span className="text-semibold header-pie-name">MosquitOasis <i className="icon-arrow-down5 change-pie"></i></span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel panel-flat">
                                        <table className="table datatable-contributions table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Amount Paid</th>
                                                    <th>Description</th>
                                                    <th>Cash Slices</th>
                                                    <th>Non-cash Slices</th>
                                                    <th>Well</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(Array(5), (e, i) => {
                                                    return <tr>
                                                        <td>10-11-2018</td>
                                                        <td>
                                                            <span class="grunt-name" style={{color: "#e46c0a", borderColor: "#e46c0a"}}>Jayson Sarino</span>
                                                        </td>
                                                        <td align="right">1,750.00</td>
                                                        <td></td>
                                                        <td align="right">1,000</td>
                                                        <td align="right">1,000</td>
                                                        <td align="right">1,000</td>
                                                        <td>
                                                            <i className="icon-pencil7 tooltip-actions" data-popup="tooltip" title="Edit"></i>
                                                            <i className="icon-trash tooltip-actions" style={{ marginLeft: "10px" }} data-popup="tooltip" title="Delete"></i>
                                                        </td>
                                                    </tr>
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </div>
        );
    }

}