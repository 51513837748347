export default class ApiHandler {
	constructor() {
		this.API_URI = "https://api.thepieslicer.com";
		this.endpoints = {
			"login": "/api/login",
			"register": "/api/register",
			"grunt":	"/api/grunt",
			"pie":		"/api/pie",
			"token":	"/api/brainTtoken",
			"contribute": "/api/contribute",
			"email":	"/api/emailteam",
			"team":		"/api/setting/team",
			"lumpsum":	"/api/lumpsum",
			"invest":	"/api/invest",
			"account":	"/api/account",
			"billing":	"/api/account/payment",
			"alerts":	"/api/account/alerts",
			"getpie": 	"/api/getpie",
			"upload":	"/api/imageUpload",
			"analyticsProjects": 	"/api/analytics/projects",
			"analyticsType": 	"/api/analytics/type",
			"analyticsTeam": 	"/api/analytics/team",
			"subscription":		"/api/subscription",
			"contributions":	"/api/report/contributions",
			"summary":	"/api/report/summary"
		}
	}

	push = (action, method, payload=null, callback=function(){}, headers=false, customEndpoint=false) => {
		method = method.toUpperCase();
		if(!this.endpoints[action] && !customEndpoint) {
			return;
		}
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function() {
			// console.log(response);
			if (this.readyState === 4) {
			var response = JSON.parse(this.responseText);

				callback({status: this.status, data: response});
			}
			
		};

		var getQueryString = "";
		if(method === "GET" && payload) {
			getQueryString = Object.keys(payload).map(key => payload[key]).join('/');
			// getQueryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('/');
			getQueryString = "/"+getQueryString;
		}

		var ep = (customEndpoint) ? action : this.API_URI + this.endpoints[action];

		xhttp.open(method, ep + getQueryString, true);
		if(headers !== false) {
			for(var i in headers) {
				xhttp.setRequestHeader(i, headers[i]);
			}
		}
		if(method !== "GET")
			xhttp.setRequestHeader("Content-Type", "application/json");

		// console.dir(payload);
		if(method !== "GET" && payload)
			xhttp.send(JSON.stringify(payload));
		else
			xhttp.send();
	}
}