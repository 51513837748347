import React from 'react';
import ReactApexChart from 'react-apexcharts'
// import '../../assets/css/customize.css';

class FairMarketComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          options: {
            labels: ['Sales', 'General Operations', 'Marketing'],
            colors:['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                },
                labels: {
                    show: false
                }
              }
            }]
          },
          series: [15000, 5000, 3600],


          optionsBar: {
            plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              vertical: true,
              dataLabels: {
                position: 'left'
              },
            }
          },
          colors: ['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },

          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: ['Sales', 'General', 'Marketing'],
          },
          yaxis: {
            labels: {
              show: true
            }
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: true
            },
            y: {
              title: {
                formatter: function () {
                  return 'Well Value: '
                }
              }
            }
          }
          },
          seriesBar: [{
            data: [19510, 1000, 2200]
          }],

        }
      }

    render() {
      return <div className="tab-pane" id="by-project-fair-market">
      <div className="row">
          <div className="col-md-5">
              <div className="chart-container text-center">
                <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width="450" />
              </div>
          </div>
          <div className="col-md-4">
              <div className="chart-container">
                <ReactApexChart options={this.state.optionsBar} series={this.state.seriesBar} type="bar" height="350" />
              </div>
          </div>
          <div className="col-md-3">
              <div className="pie-legend">
                  <div className="grunt-legend" style={{borderColor: "#bf0000"}} data-popup="tooltip" title="Mong: 85.9%" data-container="body">
                      <div className="grunt-legend-text">
                          <span className="name">Sales</span>
                          <span className="value clearfix" title="100 Slices">19,510</span>
                      </div>
                  </div>
                  <div className="grunt-legend" style={{borderColor: "#e46c0a"}} data-popup="tooltip" title="Mong: 9.7%" data-container="body">
                      <div className="grunt-legend-text">
                          <span className="name">General Operations</span>
                          <span className="value clearfix" title="100 Slices">1,000</span>
                      </div>
                  </div>
                  <div className="grunt-legend" style={{borderColor: "#17375e"}} data-popup="tooltip" title="Mong: 4.4%" data-container="body">
                      <div className="grunt-legend-text">
                          <span className="name">Marketing</span>
                          <span className="value clearfix" title="100 Slices">2,200</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    }
  }

  export default FairMarketComponent;