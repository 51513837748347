import React from 'react';
import ReactApexChart from 'react-apexcharts'
// import '../../assets/css/customize.css';
import ApiHandler from '../../utils/apiHandler';
import swal from 'sweetalert2';

const colors = ["#bf0000", "#e46c0a", "#17375e", "#77933c", "#604a7b", "#ffc000", "#4a452a",
"#31859c", "#770077", "#999900", "#533fcc", "#669999", "#42a734", "#da615e", "#1c2a36", "#868070",
"#a5379c", "#0066ff", "#e19d3b", "#6a2755", "#abd095", "#dda88c", "#d582e6", "#5b9fde", "#ad5728",
"#69deda", "#459e66", "#97e377", "#db4174", "#37664b", "#7f9156", "#589d8d", "#393c14", "#986976",
"#564c9b", "#997f24", "#e4c238", "#29173b", "#5cea64", "#8372a0", "#9c3a58", "#3b5e80", "#aeb93a",
"#9ddc3c", "#9e67e4", "#d898ac", "#a4271d", "#5e8823", "#80523a", "#e038b9", "#a82f78", "#e0c9bd",
"#b3cde9", "#ad8853", "#402a92", "#cfe685", "#6a181e", "#341520", "#3c5759", "#5a4465", "#da7525",
"#a46095", "#61c5df", "#d7817e", "#c448d4", "#89dab5", "#e54299", "#2f356c", "#654b4a", "#df3550",
"#d6875d", "#41edd0", "#79307a", "#84b55b", "#db98d6", "#bbb591", "#e56244", "#3d65a8", "#a43ae6",
"#6b6435", "#7c4a15", "#9f443f", "#22281a", "#7826a0", "#4f6ad9", "#632b3c", "#e1e9b9", "#9060b9",
"#612bd7", "#8c8891", "#a1bab5", "#7f9dbd", "#5fe081", "#4acd31", "#dc68c8", "#e4c3da", "#9797dd",
"#e32ad9", "#428ca2", "#71f032", "#e34b25", "#5c39bd", "#5bbf3f", "#b647e0", "#aab639", "#d746b9",
"#5fba78", "#de3e81", "#58802d", "#6174da", "#c39d3f", "#45357e", "#dc8539", "#b473d1", "#395931",
"#e0414e", "#54aa9e", "#852a79", "#a1a96f", "#cd6fa4", "#7c5b2b", "#5ea4d0", "#aa4629", "#495d8a",
"#d38e7b", "#a396d1", "#6e2f28", "#cb90ae", "#6a3456", "#b2455b"];


class SlicesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          legendData: [],
          options: {
            labels: ['Sales', 'General', 'Marketing'],
            colors:['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
            colors: colors,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                },
                labels: {
                    show: false
                }
              }
            }]
          },
          series: [19510, 1000, 2200],

          optionsBar: {
            plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              vertical: true,
              dataLabels: {
                position: 'left'
              },
            }
          },
          // colors: ['#bf0000', '#e46c0a', '#17375e', '#77933c', '#604a7b'],
          colors: colors,
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },

          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: ['Sales', 'General', 'Marketing'],
          },
          yaxis: {
            labels: {
              show: true
            }
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: true
            },
            y: {
              title: {
                formatter: function () {
                  return 'Well Value: '
                }
              }
            }
          }
          },
          seriesBar: [{
            data: [19510, 1000, 2200]
          }],

          }
      }


      componentDidMount() {
          
        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }

        var handle = this;
        apiHandler.push("analyticsProjects","POST",{
          id: this.props.pie_ID,
        },function(response) {
          if(response.status === 200) {
            var data = response.data;
            var seriesData = [];
            var labelData = [];
            handle.setState({legendData: data});
            
            
            for(var x = 0; x < data[0].data.length; x++) {
              try {

                if(data[0].data[x].slices === null) {
                    data[0].data[x].slices = 0;
                }
                if(data[0].data[x].project === null || data[0].data[x].project === "") {
                  data[0].data[x].project = "No Data";
                }
                seriesData.push(parseInt(data[0].data[x].slices));
                labelData.push(data[0].data[x].project);

              } catch (error) {
                
              }
            }

                  handle.setState({
                    series: seriesData,
                    options: {...handle.state.options,
                        labels: labelData
                    }
                  })
                    
                    handle.setState({
                      optionsBar: {...handle.state.optionsBar,
                        xaxis: {...handle.state.optionsBar.xaxis,
                          categories: labelData}
                    }});

                    handle.setState({
                      seriesBar: [{...handle.state.seriesBar,
                        data: seriesData 
                      }
                    ]})
                    
          }
  
          else {
              swal({
              title: "Please check your information!",
              type: "error"
              }).then(result => {
              });
          }
        }, headers
        );

      }

      componentDidUpdate(prevProps) {
        if(prevProps.pie_ID !== this.props.pie_ID) {
            this.analyticsProjects(this.props.pie_ID);
        }
      }

      analyticsProjects = (id) => {

        var token = localStorage.getItem('token');
        var userID = localStorage.getItem('userID');
        var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }

        var handle = this;
        apiHandler.push("analyticsProjects","POST",{
          id: id,
        },function(response) {
          if(response.status === 200) {
            var data = response.data;
            var seriesData = [];
            var labelData = [];
            handle.setState({legendData: data});
            

                  for(var x = 0; x < data[0].data.length; x++) {
                    try {

                      if(data[0].data[x].slices === null) {
                          data[0].data[x].slices = 0;
                      }
                      if(data[0].data[x].project === null || data[0].data[x].project === "") {
                        data[0].data[x].project = "No Data";
                      }
                      seriesData.push(parseInt(data[0].data[x].slices));
                      labelData.push(data[0].data[x].project);

                    } catch (error) {
                      
                    }
                  }

                  handle.setState({
                    series: seriesData,
                    options: {...handle.state.options,
                        labels: labelData
                    }
                  })
                    
                    handle.setState({
                      optionsBar: {...handle.state.optionsBar,
                        xaxis: {...handle.state.optionsBar.xaxis,
                          categories: labelData}
                    }});

                    handle.setState({
                      seriesBar: [{...handle.state.seriesBar,
                        data: seriesData 
                      }
                    ]})

        
          }
  
          else {
              swal({
              title: "Please check your information!",
              type: "error"
              }).then(result => {
              });
          }
        }, headers
        );

      }

    render() {
      return <div class="tab-pane active" id="by-project-slices">
      <div className="row">
          <div className="col-md-5">
              <div className="chart-container text-center">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width="450" />
              </div>
          </div>
          <div className="col-md-4">
              <div id="barChart" className="chart-container">
                <ReactApexChart options={this.state.optionsBar} series={this.state.seriesBar} type="bar" height="350" />
              </div>
          </div>
        <div className="col-md-3">
          <div className="pie-legend">
            { this.state.legendData.map((item, num) => {
              return (
                // <div className="grunt-legend" style={{borderColor: colors[num]}} data-popup="tooltip" title="Mong: 85.9%" data-container="body">
                //     <div className="grunt-legend-text">
                //         <span className="name">{item.project}</span>
                //         <span className="value clearfix" title="100 Slices">{item.slices}</span>
                //     </div>
                // </div>
                <div></div>
              );
            })} 
          </div>
        </div>
      </div>
  </div>
    }
  }
  export default SlicesComponent;