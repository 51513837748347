import React from 'react';

export default class Footer extends React.Component {
    render() {
        return(
            <div className="footer text-muted text-center">
		        &copy; {(new Date().getFullYear())}. Lake Shark Ventures, LLC
	        </div>
        );
    }
}