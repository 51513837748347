import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert2';
import ApiHandler from '../utils/apiHandler';
import '../assets/css/customize.css';

class ResetPie extends Component {
    constructor(props) {
        super(props);
        this.state ={
            open: false
        }
        
    }

    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () => {
        this.setState({open: false});
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
    }
    
    render() {
        return (
            <div id="reset-pie-modal">
                <Button id="reset-pie" variant="outlined" color="primary" onClick={this.handleClickOpen} style={{display: "none"}}>
                    Open form dialog
                </Button>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="reset-pie-dialog">
                    <DialogTitle >
                        <h5 className="modal-title"><i className="icon-info22"></i> &nbsp;Confirm Reset Pie</h5>
                    </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>Are you sure you want to reset this Pie?</label>
                            <input type="password" className="form-control" name="reset-pie-password" required placeholder="Enter Password" />
                        </div>
                        <div id="reset-pie-btns">
                            <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                            <button type="submit" className="btn btn-danger pull-left"><i className="icon-check"></i> Yes, I want to reset this Pie</button>
                        </div>
                        
                    </form>
                </DialogContent>
                {/* <DialogActions>
                <button className="btn btn-danger pull-right" onClick={this.handleClose}><i className="icon-cross"></i> Cancel</button>
                <button type="submit" className="btn btn-danger pull-left"><i className="icon-check"></i> Yes, I want to reset this Pie</button>
                </DialogActions> */}
            </Dialog>
            </div>
        );
    }
}

export default ResetPie;