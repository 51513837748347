import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';

class FindGruntWork extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{marginTop: "7px"}}>
                                    <img src={logo} style={{width: "58px" , height: "58px"}} className="img-rounded" />
                                </span>
                                <span className="text-semibold header-pie-name">
                                    MosquitOasis
                                    <i className="icon-arrow-down5 change-pie"></i>
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="panel panel-flat">
                                        <div className="panel-heading">
                                            <h5 className="panel-title">
                                                Filter Criteria
                                            </h5>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="job_title" className="form-control" placeholder="Job Title or Headline..." />
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-danger" style={{width: "100%", marginBottom: "10px"}}>Search</button>
                                                        <button type="button" className="btn btn-danger" style={{width: "100%"}}>Clear Filters</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="panel panel-flat" style={{marginBottom: "10px !important"}}>
                                        <div className="panel-body">
                                            <dl style={{border: "none", marginBottom: 0, padding: 0}}>
                                                <div className="row">

                                                    <div className="col-sm-9" style={{textAlign:"left"}}>
                                                    <a><p style={{color:"#1e88e5", fontSize:"19px"}}>Programmer</p></a>
                                                    <p>Sample gruntwork headline</p>
                                                    </div>

                                                    <div className="col-sm-3" style={{textAlign:"right"}}>
                                                    <p>20 minutes ago</p>
                                                    </div>

                                                </div>
                                                <div style={{marginTop:"15px"}}>
                                                    
                                                    <p>WE ARE EXTRAORDINARY! GROW YOUR CAREER WITH US, YUENTHAI IS ONE OF THE WORLD’S PREMIER APPAREL AND SUPPLY CHAIN SERVICE PROVIDERS AND A GLOBAL...</p>

                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="panel panel-flat" style={{marginBottom: "10px !important"}}>
                                        <div className="panel-body">
                                            <dl style={{border: "none", marginBottom: 0, padding: 0}}>
                                                <div className="row">

                                                    <div className="col-sm-9" style={{textAlign:"left"}}>
                                                    <a><p style={{color:"#1e88e5", fontSize:"19px"}}>Computer Developer</p></a>
                                                    <p>Sample gruntwork headline</p>
                                                    </div>

                                                    <div className="col-sm-3" style={{textAlign:"right"}}>
                                                    <p>20 minutes ago</p>
                                                    </div>

                                                </div>
                                                <div style={{marginTop:"15px"}}>
                                                    
                                                    <p>Responsibilities: Examining current systems. Producing specifications for new or modified systems. Implementing new systems. Other tasks may be...</p>

                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindGruntWork;