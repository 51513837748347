import React from 'react';
import HomeFooter from './HomeFooter';
import logo from '../assets/img/logo.png';
import CoolTabs from 'react-cool-tabs';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../assets/css/customize.css';
import SlicesComponent from './Analytics/SlicesComponent';
import FairMarketComponent from './Analytics/FairMarketComponent';
import TMSlicesComponent from './Analytics/TMSlicesComponent';
import TMFairMarketComponent from './Analytics/TMFairMarketComponent';
import CTSlicesComponent from './Analytics/CTSlicesComponent';
import CTFairMarketComponent from './Analytics/CTFairMarketComponent';
import ApiHandler from '../utils/apiHandler';
import swal from 'sweetalert2';


let url = '"https://api.thepieslicer.com"';

  class FairMarket extends React.Component {
    render() {
      return(
        <div>
          <FairMarketComponent />
        </div>
      );
    }
  }


  class TMFairMarket extends React.Component {
    render() {
      return(
        <div>
          <TMFairMarketComponent />
        </div>
      );
    }
  }


  class CTFairMarket extends React.Component {
    render() {
      return(
        <div>
          <CTFairMarketComponent />
        </div>
      );
    }
  }


export default class Analytics extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        pie_id: localStorage.getItem('def_id'),
        results: []
      }
    }

    componentDidMount() {

      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var pNames = [];
      var apiHandler = new ApiHandler();
          
        var headers = {
        Authorization: "Bearer " + token
        }
    
        var handle = this;  
        apiHandler.push(
        "https://api.thepieslicer.com/api/pie/" + userID,
        "GET",
        null,
        function(response) {
        if(response.status === 200) {
            var data = response.data;
            var pie_IDs = [];
            var pId = null;

            for(var i = 0; i < data.length; i++){
              pie_IDs.push(data[i].fund_id);
              pId = pie_IDs.join();
              localStorage.setItem('def_id', data[0].fund_id)
            }

            handle.getPieInfo(pId);
            
        }  
        else {
            swal({
            title: "Please check the details and try again.",
            type: "error"
            }).then(result => {
            });
        }
        }, headers, true
        );
    }

    getPieInfo = (id) => {

      var token = localStorage.getItem('token');
      var userID = localStorage.getItem('userID');
      var apiHandler = new ApiHandler();
  
      var headers = {
        Authorization: "Bearer " + token
        }
  
        var handle = this;  
        apiHandler.push("getpie","POST",{
          id: userID,
          pie_id: id
        },function(response) {
          if(response.status === 200) {
            var data = response.data;
            
            handle.setState({results: data});
  
          }
  
          else {
              swal({
              title: "Please check your information!",
              type: "error"
              }).then(result => {
              });
          }
        }, headers
        );
  
    }

    handlePieChange = (event) => {
      this.setState({pie_id: event.target.value});
    }
    
    

    render() {
        return (
            <div className="layout-boxed navbar-top">
                <div className="page-header">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4 className="pie-name-heading">
                                <span style={{ marginRight: "7px" }}><img src={logo} style={{ width: "58px", height: "58px" }} className="img-rounded" alt="" /></span>
                                {/* <span className="text-semibold header-pie-name">MosquitOasis <i className="icon-arrow-down5 change-pie"></i></span> */}
                                <select
                                  onChange={this.handlePieChange}
                                  name="name"
                                  id="select_team"
                                >
                              {this.state.results.map(function(item, i){
                                try {
                                  // return <li key={i}>{item.funds["id"]}</li>
                                 return <option  value={item.funds["id"]}>{item.funds["name"]}</option>
                                } catch (error) {
                                }
                              })}
                              </select>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom: "2vh"}}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className="panel-title" style={{fontSize: "14px"}}>By Project</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                        <Typography style={{width: "100%", fontSize: "12px"}}>
                                            <CoolTabs
                                            tabKey={'1'}
                                            style={{ width:  480, height:  500, background:  'transparent' }}
                                            activeTabStyle={{ backgroundColor: 'transparent', color:  'black' }}
                                            unActiveTabStyle={{ backgroundColor:  'transparent', color:  'black' }}
                                            activeLeftTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            activeRightTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            leftTabTitle={'Slices'}
                                            rightTabTitle={'Fair Market Value'}
                                            leftContent={<SlicesComponent pie_ID={this.state.pie_id} />}
                                            rightContent={<FairMarket/>} 
                                            />
                                        </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom: "2vh"}}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className="panel-title" style={{fontSize: "14px"}}>By Team Member</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                        <Typography style={{width: "100%", fontSize: "12px"}}>
                                            <CoolTabs
                                            tabKey={'1'}
                                            style={{ width:  480, height:  500, background:  'transparent' }}
                                            activeTabStyle={{ backgroundColor: 'transparent', color:  'black' }}
                                            unActiveTabStyle={{ backgroundColor:  'transparent', color:  'black' }}
                                            activeLeftTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            activeRightTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            leftTabTitle={'Slices'}
                                            rightTabTitle={'Fair Market Value'}
                                            leftContent={<TMSlicesComponent pie_ID={this.state.pie_id} />}
                                            rightContent={<TMFairMarket/>} 
                                            />
                                        </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom: "2vh"}}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className="panel-title" style={{fontSize: "14px"}}>By Contribution Type</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                        <Typography style={{width: "100%", fontSize: "12px"}}>
                                            <CoolTabs
                                            tabKey={'1'}
                                            style={{ width:  480, height:  500, background:  'transparent' }}
                                            activeTabStyle={{ backgroundColor: 'transparent', color:  'black' }}
                                            unActiveTabStyle={{ backgroundColor:  'transparent', color:  'black' }}
                                            activeLeftTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            activeRightTabBorderBottomStyle={{ background:  '#f06292', height:  2 }}
                                            leftTabTitle={'Slices'}
                                            rightTabTitle={'Fair Market Value'}
                                            leftContent={<CTSlicesComponent pie_ID={this.state.pie_id} />}
                                            rightContent={<CTFairMarket/>} 
                                            />
                                        </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </div>
        );
    }

}